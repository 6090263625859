<template>
  <div class="row">
    <div class="col-12 mt-5">
      <h2 class="text-center">{{ title }}</h2>
    </div>

    <div class="col-12 download-table">
      <div class="table-responsive grey-bg br-40 w100">
        <form @submit.prevent="fetchData" @keyup.enter="fetchData" v-if="isAdmin">
          <div class="search d-flex mb-4 align-items-end">
            <div class="form-group me-2">
              <label>Имя или E-mail</label>
              <input
                type="text"
                v-model="search.title"
                class="form-control mr-2"
                @change="fetchData"
              />
            </div>
            <div class="form-group me-2">
              <label>Дата от:</label>
              <input
                type="date"
                v-model.lazy="search.dateFrom"
                class="form-control"
                @change="fetchData"
                @focus="showPicker"
                @click="showPicker"
              />
            </div>
            <div class="form-group me-2">
              <label>Дата до:</label>
              <input
                type="date"
                v-model.lazy="search.dateTo"
                class="form-control"
                @change="fetchData"
                @focus="showPicker"
                @click="showPicker"
              />
            </div>
            <div>
              <a class="btn btn-info btn-lg ms-1" @click="resetSearch">
                <i class="bi bi-eraser"></i>
                Очистить
              </a>
            </div>
          </div>
        </form>
        <table class="table" v-if="orders.length">
          <thead>
            <tr>
              <th class="text-start">Дата/время</th>
              <th class="text-start">Статус</th>
              <th class="text-start">Сумма</th>
              <th class="text-start">Кол-во минут</th>
              <th class="text-start">Пакет</th>
              <th class="text-end">Кто добавил</th>
              <th v-if="isAdmin" class="text-end">Пользователь</th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr v-for="(order, index) in orders" :key="'p' + index" class="">
              <td class="text-start">{{ order.createTimeFormatted }}</td>
              <td class="text-start">{{ getOrderStatusTitle(order) }}</td>
              <td class="text-start">{{ order.total }} р.</td>
              <td class="text-start">{{ order.minutes }}</td>
              <td class="text-start">{{ getTariffName(order) }}</td>
              <td class="text-end">{{ getOrderType(order) }}</td>
              <td v-if="isAdmin" class="text-end">{{ order.user.email }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else>Нет записей</div>
      </div>
      <div v-if="loading">Загружается</div>
    </div>
    <div class="col-12">
      <div class="d-flex align-items-bottom mt-2">
        <t-pagination
          v-if="pagination.pageCount > 1"
          :link-gen="linkGen"
          :pagination="pagination"
          @page-selected="pageClickTPag"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { paginationOptions } from '@/modules/dialoger/decorators/common.js'
import TPagination from '@/components/layout/TPagination.vue'
import { getUserTitle } from '@/modules/users/decorator/userDecorator.js'
import { getOrderStatusTitle, getTariffName } from '@/modules/dialoger/decorators/orderDecorator.js'
import { useAuthUserStore } from '@/stores/user.js'
import { canAccessToUsers } from '@/modules/dialoger/access/access.js'
import { getOrderType } from '@/modules/dialoger/decorators/orderDecorator.js'

export default {
  name: 'OrderLog',
  components: { TPagination },

  props: {
    hideSearch: {}
  },
  data() {
    return {
      pagination: {
        totalCount: 0,
        pageNumber: 1,
        pageCount: 0,
        pageSize: 10,
        options: paginationOptions
      },
      search: {
        title: '',
        dateFrom: '',
        dateTo: ''
      },
      error: null,
      loading: true,
      orders: []
    }
  },
  computed: {
    title() {
      if (this.isAdmin) {
        return 'История платежей'
      } else {
        return 'История покупок'
      }
    },
    isAdmin() {
      const store = useAuthUserStore()
      return this.canAccessToUsers(store)
    }
  },
  watch: {},

  created: function () {},

  mounted: function () {
    this.checkUrl()
  },

  methods: {
    showPicker(e) {
      e.target.showPicker()
    },
    resetSearch() {
      this.search.title = ''
      this.search.dateTo = ''
      this.search.dateFrom = ''
      this.fetchData()
    },
    getUserTitle,
    getTariffName,
    getOrderStatusTitle,
    linkGen(pageNum) {
      return {
        name: 'tariff-list',
        params: { page: pageNum }
      }
    },
    fetchData: function () {
      this.tariffPlans = []
      this.loading = true
      let usersUrl = '/dialoger/order/'
      let params = {
        limit: this.pagination.pageSize,
        page: this.pagination.pageNumber,
        search: this.search.title,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo
      }
      this.$http
        .get(usersUrl, {
          params: params
        })
        .then((response) => {
          this.loading = false
          this.orders = response.data
          this.pagination.pageSize = parseInt(response.headers['x-pagination-per-page'])
          this.pagination.totalCount = parseInt(response.headers['x-pagination-total-count'])
          this.pagination.pageCount = parseInt(response.headers['x-pagination-page-count'])
        })
        .catch(() => {
          this.loading = false
        })
    },
    checkUrl: function () {
      this.fetchData()
    },

    pageClickTPag(pageNum) {
      this.pagination.pageNumber = pageNum
      this.fetchData()
      return false
    },

    canAccessToUsers,
    getOrderType
  }
}
</script>
