import { createRouter, createWebHistory } from 'vue-router'
import TariffPlan from '@/modules/dialoger/views/tariffplan/TariffPlan.vue'
import UserRoutes from '@/modules/users/userRouter.js'
import FileRoutes from '@/modules/file/fileRouter.js'
import DialogerRoutes from '@/modules/dialoger/dialogerRouter.js'
import { checkGuest } from '@/modules/dialoger/access/access.js'
import { useAuthUserStore } from '@/stores/user.js'
let mainRoutes = [
  {
    path: '/',
    name: 'start',
    component: TariffPlan,
    meta: {
      requiresAuth: true,
      checkRule: checkGuest
    }
  }
]
let routes = mainRoutes.concat(UserRoutes, FileRoutes, DialogerRoutes)
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

router.beforeEach((to, from, next) => {
  const UserStore = useAuthUserStore()
  // hack to bypass <b-pagination-nav> @page-click glitch
  if (UserStore.linkClickPrevented) {
    UserStore.preventLinkClick(false)
    return
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!checkGuest(UserStore)) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else if (to.meta.checkRule && !to.meta.checkRule(UserStore)) {
      next({
        path: '/',
        name: 'home'
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router
