import { defineStore } from 'pinia'

import { apiSetToken } from '@/plugins/api/helpers'

export const useAuthStore = defineStore('authToken', {
  state: () => ({
    token: null
  }),
  getters: {
    isLogged: (state) => !!state.token,
    isGuest: (state) => !state.token,
    getToken: (state) => {
      return getToken(state.token)
    }
  },
  actions: {
    setToken(token) {
      this.token = token
      apiSetToken(getToken(this.token))
    }
  },
  persist: true
})

const getToken = (token) => {
  if (!token) {
    return null
  }

  return `Bearer ${token}`
}
