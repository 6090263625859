<template>
  <form v-if="dataLoaded" id="user-form" ref="user" class="user-form admin-form" novalidate>
    <div class="mb-4">
      <div class="label">Заголовок</div>
      <input v-model="tariff.title" required class="form-control" maxlength="256" />
    </div>

    <div class="mb-4">
      <div class="label">Стоимость</div>
      <input
        v-model.number="tariff.price"
        type="text"
        @focus="clearField('price')"
        placeholder=""
        required
        class="form-control"
        @input="fixNumber"
        @change="fixNumber"
        v-mask="'##########'"
      />
    </div>

    <div class="mb-4">
      <div class="label">Количество минут</div>
      <input
        v-model.number="tariff.count"
        placeholder=""
        @focus="clearField('count')"
        type="text"
        required
        class="form-control"
        @input="fixNumber"
        @change="fixNumber"
        v-mask="'##########'"
      />
    </div>

    <div class="col-12 mb-4">
      <div class="label">Описание</div>
      <textarea
        v-model="tariff.body"
        placeholder=""
        autocomplete="new"
        class="form-control"
        required
        maxlength="10000"
      ></textarea>
    </div>

    <div class="d-sm-grid d-md-flex">
      <button
        class="btn btn-success btn-lg mb-2 me-md-3 d-flex justify-content-center align-items-center"
        @click="createOrUpdate"
      >
        {{ submitButton }}
      </button>

      <button
        v-if="id !== 'new'"
        class="btn btn-warning btn-lg mb-2 me-md-3 d-flex justify-content-center align-items-center"
        @click="cancelUpdate"
      >
        К списку
      </button>

      <button
        v-if="tariff.id > 0"
        class="btn btn-danger btn-lg mb-2 me-md-3 d-flex justify-content-center align-items-center"
        @click="remove"
      >
        Удалить
      </button>
    </div>
  </form>
</template>

<script>
import clone from 'just-clone'
import { useToast } from 'vue-toastification'
export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'TariffPlanForm',
  props: ['tariffModel', 'dataLoaded'],
  data() {
    return {
      tariff: {
        price: '',
        count: ''
      },
      loading: false,
      submitButton: 'Сохранить'
    }
  },
  computed: {
    id() {
      return this.tariff.id
    }
  },
  watch: {
    $route() {},
    tariffModel: {
      immediate: true,
      handler(obj) {
        this.tariff = clone(obj)
      }
    }
  },
  created: function () {},
  mounted: function () {},
  methods: {
    fixNumber(e) {
      if (parseInt(e.target.value)) {
        e.target.value = parseInt(e.target.value)
      } else {
        e.target.value = ''
      }
    },
    clearField(field) {
      if (this.tariff[field] === 0) {
        this.tariff[field] = ''
      }
    },
    update: function () {
      let updateUrl
      updateUrl = '/dialoger/tariff-plans/' + this.id
      this.loading = true
      this.$http
        .put(updateUrl, this.processUserVariable(this.tariff))
        .then(() => {
          this.loading = false
          this.toast.success('Тариф отредактирован')
        })
        .catch(() => {
          this.loading = false
          this.toast.error('Не удалось отредактировать тариф')
        })
    },
    create: function () {
      let updateUrl
      updateUrl = '/dialoger/tariff-plans'
      this.loading = true
      this.$http
        .post(updateUrl, this.processUserVariable(this.tariff))
        .then((response) => {
          this.tariff = response.data
          this.toast.success('Тариф добавлен')
          this.$router.push({ name: 'tariff-list' })
        })
        .catch(() => {
          this.loading = false
          this.toast.error('Не удалось добавить пользователя')
        })
    },

    processUserVariable(tariff) {
      let params = {
        title: tariff.title,
        body: tariff.body,
        price: tariff.price,
        count: tariff.count
      }
      if (tariff.id && tariff.id !== 'new') {
        params.id = tariff.id
      }
      return params
    },

    createOrUpdate: function (event) {
      event.preventDefault()
      if (!this.tariff.count) {
        this.tariff.count = ''
      }
      if (!this.tariff.price) {
        this.tariff.price = ''
      }
      let form = document.getElementById('user-form')
      let valid = form.checkValidity()
      form.classList.add('was-validated')
      if (valid) {
        if (this.id === 'new') {
          this.create()
        } else {
          this.update()
        }
        form.classList.remove('was-validated')
      } else {
        this.toast.error('В форме содержатся ошибки')
        return false
      }
    },
    remove: function (e) {
      e.preventDefault()
      if (confirm('Вы точно хотите удалить тариф?')) {
        let deleteUrl = 'dialoger/tariff-plan/delete?id=' + this.id
        this.true = false
        this.$http
          .delete(deleteUrl, {})
          .then(() => {
            this.loading = false
            this.$router.push({ name: 'tariff-list' })
            this.toast.success('Тариф удален')
          })
          .catch(() => {
            this.loading = false
            this.toast.error('Не удалось удалить тариф')
          })
      }
    },

    cancelUpdate() {
      this.$router.push({ name: 'tariff-list' })
    }
  }
}
</script>
