// Хранилище Pinia
import { defineStore } from 'pinia'

export const useAuthUserStore = defineStore('authUser', {
  state: () => ({
    isGuest: true,
    id: null,
    name: 'Гость',
    authKey: '',
    type: '',
    profile: {},
    enabled: true,
    linkClickPrevented: false,
    access: {}
  }),
  getters: {},
  actions: {
    logout() {
      this.$reset()
    },
    // мутации теперь могут становиться действиями, вместо `state` в качестве первого аргумента используется `this`
    setUserInfo(payload) {
      this.isGuest = payload.isGuest
      this.id = payload.id
      this.type = payload.userType
      this.enabled = payload.enabled
      this.name = payload.name
      this.authKey = payload.authKey
      this.access = payload.access
      this.profile = payload.profile
      this.linkClickPrevented = payload.linkClickPrevented
    },
    preventLinkClick(state) {
      this.linkClickPrevented = state
    }
  },
  persist: true
})
