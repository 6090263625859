import FileUpload from '@/modules/file/views/FileUpload.vue'
import FileReport from '@/modules/file/views/FileReport.vue'
import FileHistory from '@/modules/file/views/FileHistory.vue'
import { checkGuest } from '@/modules/dialoger/access/access.js'
export default [
  {
    path: '/history',
    name: 'file-history',
    components: {
      default: FileHistory
    },
    meta: {
      requiresAuth: true,
      checkRule: checkGuest
    }
  },
  {
    path: '/upload',
    name: 'file-upload',
    components: {
      default: FileUpload
    },
    meta: {
      requiresAuth: true,
      checkRule: checkGuest
    }
  },
  {
    path: '/report/:id',
    name: 'file-report',
    components: { default: FileReport },
    meta: {
      requiresAuth: true,
      checkRule: checkGuest
    }
  }
]
