import UserLogin from '@/modules/users/views/UserLogin.vue'
import UserRegister from '@/modules/users/views/UserRegister.vue'
import UserAccount from '@/modules/users/views/UserAccount.vue'
import UserPassword from '@/modules/users/views/UserPassword.vue'
import { checkGuest, canAccessToUsers } from '@/modules/dialoger/access/access.js'
import UserForgot from '@/modules/users/views/UserForgot.vue'
import UserList from '@/modules/users/views/UserList.vue'
import UserView from '@/modules/users/views/UserView.vue'
export default [
  {
    path: '/login',
    name: 'user-login',
    components: {
      default: UserLogin
    }
  },
  {
    path: '/register',
    name: 'user-register',
    components: {
      default: UserRegister
    }
  },
  {
    path: '/forgot',
    name: 'user-forgot',
    components: {
      default: UserForgot
    }
  },
  {
    path: '/edit',
    name: 'user-edit',
    components: { default: UserAccount },
    meta: {
      requiresAuth: true,
      checkRule: checkGuest
    }
  },
  {
    path: '/password-change',
    name: 'user-password',
    components: { default: UserPassword },
    meta: {
      requiresAuth: true,
      checkRule: checkGuest
    }
  },

  {
    path: '/user-create',
    name: 'user-create',
    components: {
      default: UserView
    },
    meta: {
      requiresAuth: true,
      checkRule: canAccessToUsers
    }
  },
  {
    path: '/users/:id/update',
    name: 'user-update',
    components: {
      default: UserView
    },
    meta: {
      requiresAuth: true,
      checkRule: canAccessToUsers
    }
  },
  {
    path: '/users/list/:page?',
    name: 'user-list',
    components: {
      default: UserList
    },
    meta: {
      requiresAuth: true,
      checkRule: canAccessToUsers
    }
  }
]
