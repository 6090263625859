<template>
  <div class="col">
    <div class="loading" v-if="loading">
      <div class="spin"></div>
      <div class="wait text-center">
        Ваш файл обрабатывается
        <div class="time">Осталось 5 минут</div>
      </div>
    </div>
    <div class="logon-form grey-bg br-40 mx-auto" v-if="!loading">
      <div class="d-flex text-center mb-0">
        <h2>Выберите этапы распознавания</h2>
      </div>
      <form class="upload-form">
        <div class="d-flex mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="options.diar"
              id="flexCheckDefault"
            />
            <label for="flexCheckDefault"></label>
          </div>
          <div class="accordion" id="accordion1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Диаризация и транскрибиция
                  <span class="icons">
                    <i class="bi bi-file-text"></i>
                    <i class="bi bi-play-circle"></i>
                  </span>
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordion1"
              >
                <div class="accordion-body">
                  разделение текста по тематическим сегментам. <br />
                  Прикрепить нужно текстовый файл
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="options.segment"
              id="flexCheckDefault1"
            />
            <label for="flexCheckDefault1"></label>
          </div>
          <div class="accordion" id="accordion2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Сегментация
                  <span class="icons">
                    <i class="bi bi-file-text"></i>
                    <i class="bi bi-play-circle"></i>
                  </span>
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordion2"
              >
                <div class="accordion-body">
                  разделение текста по тематическим сегментам. <br />
                  Прикрепить нужно текстовый файл
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="options.tagging"
              id="flexCheckDefault2"
            />
            <label for="flexCheckDefault2"></label>
          </div>
          <div class="accordion" id="accordion3">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTree"
                  aria-expanded="true"
                  aria-controls="collapseTree"
                >
                  Тегирование
                  <span class="icons">
                    <i class="bi bi-file-text"></i>
                    <i class="bi bi-play-circle"></i>
                  </span>
                </button>
              </h2>
              <div
                id="collapseTree"
                class="accordion-collapse collapse"
                aria-labelledby="headingTree"
                data-bs-parent="#accordion3"
              >
                <div class="accordion-body">
                  разделение текста по тематическим сегментам. <br />Прикрепить нужно текстовый файл
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="options.analiz"
              id="flexCheckDefault3"
            />
            <label for="flexCheckDefault3"></label>
          </div>
          <div class="accordion" id="accordion4">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Анализ тональности
                  <span class="icons">
                    <i class="bi bi-file-text"></i>
                    <i class="bi bi-play-circle"></i>
                  </span>
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordion4"
              >
                <div class="accordion-body">
                  разделение текста по тематическим сегментам. <br />
                  Прикрепить нужно текстовый файл
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="options.summary"
              id="flexCheckDefault4"
            />
            <label for="flexCheckDefault4"></label>
          </div>
          <div class="accordion" id="accordion5">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                >
                  Суммаризация
                  <span class="icons">
                    <i class="bi bi-file-text"></i>
                  </span>
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordion5"
              >
                <div class="accordion-body">
                  разделение текста по тематическим сегментам. <br />
                  Прикрепить нужно текстовый файл
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-grid">
          <button class="btn btn-primary btn-lg" id="register" @click="chooseFile">
            Перейти к загрузке
          </button>
          <input type="file" ref="file" id="file" @input="startUpload" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'FileUpload',
  methods: {
    toReport() {
      this.$router.push({ name: 'file-report' })
    },
    chooseFile(e) {
      e.preventDefault()
      this.$refs.file.click()
    },
    startUpload(e) {
      let formData = new FormData()
      let hasOptions = false
      for (let option in this.options) {
        if (this.options[option]) {
          hasOptions = true
        }
        formData.append(option, this.options[option])
      }
      if (!hasOptions) {
        this.toast.error('Выберите хотя бы один этап ')
        return false
      }
      this.file = e.target.files[0]
      console.log(this.file)

      formData.append('file', this.file)

      this.$http
        .post('/dialoger/download/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.data.status === 'ok') {
            this.loading = true
            setTimeout(() => this.toReport(), 3000)
          } else {
            for (let error in response.data.errors) {
              this.toast.error(response.data.errors[error])
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  data() {
    return {
      options: {
        diar: 0,
        segment: 0,
        tagging: 0,
        analiz: 0,
        summary: 0
      },
      file: null,
      loading: false
    }
  }
}
</script>
