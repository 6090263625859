export const STATUS_NEW = 1
export const STATUS_PROGRESS = 2
export const STATUS_PROCESSED = 5
export const STATUS_FINISHED = 3
export const STATUS_ERROR = 4;
export const getDownloadTime = function (download) {
  if (download && download.file && download.downloadTime) {
    return download.downloadTime
  } else return ['Неизвестно']
}

export const getDownloadFileName = function (download) {
  if (download && download.file) {
    return download.file.name + '.' + download.file.extension
  }
  return ['Без имени']
}

export const getDownloadFileExtension = function (download) {
  if (download && download.file) {
    return download.file.extension
  }
  return ['Нет расширения']
}

export const getDownloadSize = function (download) {
  if (download.fileSize > 1048576) {
    return (download.fileSize / 1048576).toFixed(2) + 'МБ'
  }
  if (download.fileSize > 1024) {
    return (download.fileSize / 1024).toFixed(1) + 'КБ'
  }
  return download.fileSize + 'Б'
}

export const getTrackDuration = function (download) {
  if (download && download.trackLength) {
    let seconds = download.trackLength
    let hours = Math.floor(seconds / 3600)
    seconds -= hours * 3600
    let minutes = Math.floor(seconds / 60)
    seconds -= minutes * 60
    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return hours + ':' + minutes + ':' + seconds
  }
  return 'Не известно'
}

export const getStatsMinutes = function (duration) {
  return Math.floor(duration / 60)
}

export const getStatsMegaBytes = function (bytes) {
  return (bytes / 1024 / 1024).toFixed(2)
}
