export const TYPE_ADMIN = 1
export const TYPE_CLIENT = 4

export const userTypeTitles = {
  [TYPE_ADMIN]: 'Администратор',
  [TYPE_CLIENT]: 'Клиент'
}

export const userTypesTitlesAsSelectOptions = [
  { value: TYPE_ADMIN, text: 'Администратор' },
  { value: TYPE_CLIENT, text: 'Клиент' }
]

export const getUserTypeTitle = function (user) {
  if (!user.type) {
    return '[Не определен]'
  }

  if (!(user.type in userTypeTitles)) {
    return '[Не определен]'
  }
  return userTypeTitles[user.type]
}
export const convertToMinutes = function (secondCount) {
  return Math.round(secondCount / 60)
}
export const getUserTitle = function (user) {
  if (!user) {
    return '[Не назначен]'
  }
  if (user.title) {
    return user.title
  }
  let firstName = user.name || ''
  let lastName = user.surname || ''
  let patronymic = user.patronymic || ''
  let name = lastName + ' ' + firstName + ' ' + patronymic
  name = name.trim()
  if (name && name !== '') {
    return name
  }
  if (!name && user.username) {
    return user.username
  } else {
    return '[Без имени]'
  }
}
export const getShortUserName = function (user) {
  if (!user) {
    return '[Не назначен]'
  }
  if (user.title) {
    return user.title
  }
  let firstName = user.name ? user.name[0] : ''
  let lastName = user.surname || ''
  let patronymic = user.patronymic ? user.patronymic[0] : ''
  let name = lastName + ' ' + firstName + ' ' + patronymic
  name = name.trim()
  if (name && name !== '') {
    return name
  }
  if (!name && user.username) {
    return user.username
  } else {
    return '[Без имени]'
  }
}

export const emptyUser = {
  id: 'new',
  type: null,
  manager_id: '',
  managerName: '',
  firstName: '',
  lastName: '',
  patronymic: '',
  gender: '',
  phone: '',
  email: '',
  phoneSearch: '',
  birthday: '',
  userBirthPlace: '',
  country: '',
  town: '',
  addressPassport: '',
  addressLiving: '',
  // 'addressPassport': '',
  // 'addressLiving': '',
  address: '',
  postAddress: '',
  legalAddress: '',
  phones: [{ phone: '', type: 'mobile' }],
  emails: [{ email: '', type: 'personal' }],
  passportSerial: '',
  passportNumber: '',
  passportAddress: '',
  passportIssuedDate: '',
  passportIssuedByTitle: '',
  passportIssuedByCode: '',

  numerationStart: 1,
  numeration: null,
  secondsCount: 0
}
