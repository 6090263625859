<template>
  <header id="header">
    <div class="container-fluid h-100">
      <div class="row align-items-center h-100">
        <div class="col-4">
          <a href="https://dialoger.tech/">
            <img src="../../assets/img/logo.png" id="logo" alt="Logotype" />
          </a>
        </div>

        <div class="col" v-if="!isLogged && isLoginPath">
          <router-link
            :to="{ name: 'user-login' }"
            class="btn btn-dark btn-lg float-end"
            id="login-button"
            >Вход</router-link
          >
        </div>
        <template v-else-if="isLogged">
          <div class="col d-none d-md-block">
            <ul class="nav justify-content-end" id="main-menu">
              <li class="nav-item" v-if="!isAdmin">
                <router-link class="nav-link" :to="{ name: 'file-history' }" active-class="active"
                >Распознавание</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'tariff-plan' }"
                  class="nav-link"
                  aria-current="page"
                  active-class="active"
                >
                  <template v-if="isAdmin"> История платежей </template>
                  <template v-else> Тарифы и оплата </template>
                </router-link>
              </li>

              <li class="nav-item" v-if="!isAdmin">
                <router-link :to="{ name: 'question' }" class="nav-link" active-class="active"
                  >Тех. поддержка</router-link
                >
              </li>
              <li class="nav-item" v-if="canAccessToUsers(store)">
                <router-link :to="{ name: 'user-list' }" class="nav-link" active-class="active"
                  >Пользователи</router-link
                >
              </li>
              <li class="nav-item" v-if="canAccessToUsers(store)">
                <router-link :to="{ name: 'tariff-list' }" class="nav-link" active-class="active"
                  >Тарифы</router-link
                >
              </li>
              <li class="nav-item" v-if="canAccessToUsers(store)">
                <router-link :to="{ name: 'settings' }" class="nav-link" active-class="active"
                  >Настройки</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-md-1 col-6">
            <router-link :to="{ name: 'user-edit' }" class="profile float-end">
              <i class="bi bi-person"></i>
            </router-link>
          </div>
          <div class="col-md-1 col-2 text-end menubar d-block d-md-none">
            <a
              data-bs-toggle="collapse"
              href="#collapse"
              role="button"
              aria-expanded="false"
              aria-controls="collapse"
            >
              <i class="bi bi-list"></i>
            </a>
          </div>
        </template>
      </div>
    </div>
  </header>
  <div class="collapse" id="collapse">
    <ul class="nav justify-content-end flex-column text-end">
      <li class="nav-item">
        <router-link
          :to="{ name: 'tariff-plan' }"
          class="nav-link"
          aria-current="page"
          active-class="active"
        >
          <template v-if="isAdmin"> История платежей </template>
          <template v-else> Тарифы и оплата </template>
        </router-link>
      </li>
      <li class="nav-item" v-if="!isAdmin">
        <router-link class="nav-link" :to="{ name: 'file-history' }" active-class="active"
          >История загрузок</router-link
        >
      </li>
      <li class="nav-item" v-if="!isAdmin">
        <router-link :to="{ name: 'question' }" class="nav-link" active-class="active"
          >Тех. поддержка</router-link
        >
      </li>
      <li class="nav-item" v-if="canAccessToUsers(store)">
        <router-link :to="{ name: 'user-list' }" class="nav-link" active-class="active"
          >Пользователи</router-link
        >
      </li>
      <li class="nav-item" v-if="canAccessToUsers(store)">
        <router-link :to="{ name: 'tariff-list' }" class="nav-link" active-class="active"
          >Тарифы</router-link
        >
      </li>
      <li class="nav-item" v-if="canAccessToUsers(store)">
        <router-link :to="{ name: 'settings' }" class="nav-link" active-class="active"
          >Настройки</router-link
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { useAuthUserStore } from '@/stores/user.js'
import { canAccessToUsers } from '@/modules/dialoger/access/access.js'
export default {
  setup() {
    const store = useAuthUserStore()

    return { store }
  },
  components: {},
  data() {
    return {
      activeMenuItem: 'plan'
    }
  },
  created() {},
  computed: {
    isLogged() {
      const store = useAuthUserStore()
      return !store.isGuest
    },
    isAdmin() {
      const store = useAuthUserStore()
      return this.canAccessToUsers(store)
    },
    isLoginPath() {
      return this.$route?.name !== 'user-login'
    }
  },

  mounted: function () {},

  methods: { canAccessToUsers }
}
</script>
