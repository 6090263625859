<script>
import { getCurrentInstance, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { useAuthUserStore } from '@/stores/user.js'
export default {
  setup() {
    const userStore = useAuthUserStore()
    const http = getCurrentInstance().appContext.config.globalProperties.$http
    const toast = useToast()
    const name = ref('')
    const subject = ref('')
    const text = ref('')

    name.value = userStore.profile.name
    const submit = (e) => {
      e.preventDefault()
      let valid = name.value.length && subject.value.length && text.value.length
      if (!valid) {
        toast.error('Заполните все поля')
      } else {
        let messageUrl = '/dialoger/default/message'
        http
          .post(messageUrl, {
            name: name.value,
            subject: subject.value,
            text: text.value
          })
          .then((response) => {
            if (response.data.status === 'ok') {
              toast.success('Сообщение отправлено, ожидайте ответ на почту')
              subject.value = ''
              text.value = ''
              name.value = '';
            }
          })
          .catch(() => {
            toast.error('Что-то пошло не так :(')
          })
      }
    }
    return { name, subject, text, submit }
  }
}
</script>
<template>
  <div class="col">
    <div class="logon-form grey-bg br-40 mx-auto">
      <div class="d-flex text-center mb-0">
        <h2>Задать вопрос</h2>
      </div>
      <form @submit="submit">
        <div class="mb-3">
          <input
            type="text"
            placeholder="Введите ваше имя"
            v-model="name"
            class="form-control white-bg"
          />
        </div>
        <div class="mb-3">
          <input
            type="text"
            placeholder="Ваш телефон или почта"
            v-model="subject"
            class="form-control white-bg"
          />
        </div>
        <div class="mb-4">
          <textarea
            class="form-control white-bg"
            v-model="text"
            rows="5"
            placeholder="Ваш вопрос"
            maxlength="1000"
          ></textarea>
        </div>

        <div class="d-grid">
          <button type="button" class="btn btn-primary btn-lg" id="register" @click="submit">
            Отправить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
