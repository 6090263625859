<template>
  <div v-if="loading" class="loading">
    <div class="spin"></div>
  </div>
  <div class="row">
    <div class="col-12 mb">
      <div class="mt-4 d-md-flex align-items-center">
        <h2 class="admin-h">Пользователи</h2>
        <div class="align-self-center plan d-sm-grid">
          <router-link
            :to="{ name: 'user-create' }"
            class="btn btn-primary btn-lg d-flex justify-content-center align-items-center"
          >
            <i class="bi bi-plus"></i> Добавить
          </router-link>
        </div>
      </div>
    </div>

    <div class="col-12 download-table">
      <div class="table-responsive grey-bg br-40 w100">
        <div class="search d-md-flex mb-4" v-on:keyup.enter="fetchData">
          <div class="d-md-flex align-items-center flex-wrap">
            <div class="me-2 d-sm-grid">
              <div class="label me-2">Имя</div>
              <input v-model="search.name" tabindex="1" class="form-control mb-md-0 mb-2" />
            </div>
            <div class="me-2 d-sm-grid">
              <div class="form-label me-2">E-mail</div>
              <input v-model="search.email" tabindex="3" class="form-control mb-md-0 mb-2" />
            </div>

            <div class="align-self-end me-2">
              <button class="btn btn-lg btn-success ms-1" @click="fetchData">
                <i class="bi bi-search" /> Найти
              </button>
              <button class="btn btn-info btn-lg ms-1" @click.prevent="resetData">
                <i class="bi bi-bootstrap-reboot" /> Очистить
              </button>
            </div>

            <div class="ms-2 align-self-end">
              <select v-model="pagination.pageSize" @change="fetchData">
                <option
                  v-for="option in pagination.options"
                  v-bind:key="option.value"
                  v-bind:value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div v-if="!loading" class="align-self-end mt-2 ms-2">
              Найдено: {{ pagination.totalCount }}
            </div>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th class="text-start">Имя</th>
              <th class="text-start">Тип</th>
              <th class="text-start">E-mail</th>
              <th>Израсходовано / Начислено (мин)</th>
              <th>Заполнено / Всего (Mб)</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr v-for="(user, index) in users" :key="'p' + index" class="">
              <td>
                <router-link :to="{ name: 'user-update', params: { id: user.id } }">
                  {{ getUserTitle(user) }}
                </router-link>
              </td>
              <td class="text-start">{{ getUserTypeTitle(user) }}</td>
              <td>{{ user.email }}</td>
              <td>{{ getExpensedMinutes(user) }}/{{ user.orderedMinutes }}</td>
              <td>{{ user.formattedUserSpace }}/{{ getUserMaxSpace(user) }}</td>

              <td>
                <a role="button" @click="deleteUser(user.id)"
                  ><i class="bi bi-trash text-danger"></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading">Загружается</div>
    </div>
    <div class="col-12">
      <div class="d-flex align-items-bottom mt-2">
        <t-pagination
          v-if="pagination.pageCount > 1"
          :link-gen="linkGen"
          :pagination="pagination"
          @page-selected="pageClickTPag"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUserTitle,
  getUserTypeTitle,
  convertToMinutes
} from '@/modules/users/decorator/userDecorator'
import { paginationOptions } from '@/modules/dialoger/decorators/common.js'
import TPagination from '@/components/layout/TPagination.vue'
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'UserList',
  components: { TPagination },

  props: {
    hideSearch: {}
  },
  data() {
    return {
      pagination: {
        totalCount: 0,
        pageNumber: 1,
        pageCount: 0,
        pageSize: 10,
        options: paginationOptions
      },
      search: {
        name: '',
        email: ''
      },
      error: null,
      loading: false,
      users: []
    }
  },
  computed: {},
  watch: {},

  created: function () {},

  mounted: function () {
    this.checkUrl()
  },

  methods: {
    getUserMaxSpace(user) {
      if (user.maxUserSpace) {
        return user.maxUserSpace
      } else {
        return 0
      }
    },
    getExpensedMinutes(user) {
      if (user.orderedMinutes) {
        return user.orderedMinutes - convertToMinutes(user.secondCount)
      } else {
        return 0
      }
    },
    convertToMinutes,
    deleteUser(id) {
      if (confirm('Вы действительно хотите удалить пользователя?')) {
        let deleteUrl = '/users/admin/' + id
        this.$http
          .delete(deleteUrl)
          .then(() => {
            this.loading = false
            this.fetchData()
            // this.$eventHub.$emit('update-user-info', this.user);
            this.toast.success('Пользователь удален')
          })
          .catch(() => {
            this.loading = false
            this.toast.error('Не удалось удалить пользователя')
          })
      }
    },
    getUserTitle,
    getUserTypeTitle,
    fetchData: function () {
      this.users = []
      this.loading = true
      let usersUrl = '/users/admin/'
      let params = {
        limit: this.pagination.pageSize,
        search: this.search.name,
        email: this.search.email,
        page: this.pagination.pageNumber
      }
      this.$http
        .get(usersUrl, {
          params: params
        })
        .then((response) => {
          this.loading = false
          this.users = response.data
          this.pagination.pageSize = parseInt(response.headers['x-pagination-per-page'])
          this.pagination.totalCount = parseInt(response.headers['x-pagination-total-count'])
          this.pagination.pageCount = parseInt(response.headers['x-pagination-page-count'])
        })
        .catch(() => {
          this.loading = false
        })
    },
    checkUrl: function () {
      this.fetchData()
    },
    linkGen(pageNum) {
      return {
        name: 'user-list',
        params: { page: pageNum }
      }
    },
    pageClickTPag(pageNum) {
      this.pagination.pageNumber = pageNum
      this.search.page = pageNum
      this.fetchData()
      return false
    },
    resetData() {
      this.search = {
        name: '',
        email: ''
      }
      this.fetchData()
    }
  }
}
</script>
