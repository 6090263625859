<template>
  <div v-if="loading" class="loading">
    <div class="spin"></div>
  </div>
  <div class="row">
    <div class="col-12 mb">
      <div class="mt-4 d-md-flex align-items-center">
        <h2 class="admin-h">Тарифы</h2>
        <div class="align-self-center plan d-sm-grid">
          <router-link
            :to="{ name: 'tariff-create' }"
            class="btn btn-primary btn-lg d-flex justify-content-center align-items-center"
          >
            <i class="bi bi-plus"></i> Добавить
          </router-link>
        </div>
      </div>
    </div>

    <div class="col-12 download-table">
      <div class="table-responsive grey-bg br-40 w100">
        <div class="search d-md-flex mb-4" @keydown.enter="fetchData">
          <div class="d-md-flex align-items-center flex-wrap">
            <div class="me-2 d-sm-grid">
              <div class="label me-2">Наименование</div>
              <input v-model="search.title" tabindex="1" class="form-control mb-md-0 mb-2" />
            </div>

            <div class="align-self-end me-2">
              <button class="btn btn-success btn-lg ms-1" @click="fetchData">
                <i class="bi bi-search" /> Найти
              </button>
              <button class="btn btn-info btn-lg ms-1" @click.prevent="resetData">
                <i class="bi bi-bootstrap-reboot" /> Очистить
              </button>
            </div>

            <div class="ms-2 align-self-end">
              <select v-model="pagination.pageSize" @change="fetchData">
                <option
                  v-for="option in pagination.options"
                  v-bind:key="option.value"
                  v-bind:value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div v-if="!loading" class="align-self-end mt-2 ms-2">
              Найдено: {{ pagination.totalCount }}
            </div>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th class="text-start">Наименование</th>
              <th class="text-start">Стоимость</th>
              <th class="text-start">Кол-во минут</th>

              <th></th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr v-for="(tariff, index) in tariffPlans" :key="'p' + index" class="">
              <td>
                <router-link :to="{ name: 'tariff-update', params: { id: tariff.id } }">
                  {{ tariff.title }}
                </router-link>
              </td>
              <td class="text-start">{{ tariff.price }} руб.</td>
              <td>{{ tariff.count }} мин</td>
              <td>
                <i
                  class="bi bi-trash text-danger"
                  role="button"
                  @click="deleteTariff(tariff.id)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading">Загружается</div>
    </div>
    <div class="col-12">
      <div class="d-flex align-items-bottom mt-2">
        <t-pagination
          v-if="pagination.pageCount > 1"
          :link-gen="linkGen"
          :pagination="pagination"
          @page-selected="pageClickTPag"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { paginationOptions } from '@/modules/dialoger/decorators/common.js'
import TPagination from '@/components/layout/TPagination.vue'
export default {
  name: 'TariffPlanList',
  components: { TPagination },

  props: {
    hideSearch: {}
  },
  data() {
    return {
      pagination: {
        totalCount: 0,
        pageNumber: 1,
        pageCount: 0,
        pageSize: 10,
        options: paginationOptions
      },
      search: {
        title: ''
      },
      error: null,
      loading: true,
      tariffPlans: []
    }
  },
  computed: {},
  watch: {},

  created: function () {},

  mounted: function () {
    this.checkUrl()
  },

  methods: {
    fetchData: function () {
      this.tariffPlans = []
      this.loading = true
      let usersUrl = '/dialoger/tariff-plan/'
      let params = {
        limit: this.pagination.pageSize,
        search: this.search.title,
        email: this.search.email,
        page: this.pagination.pageNumber
      }
      this.$http
        .get(usersUrl, {
          params: params
        })
        .then((response) => {
          this.loading = false
          this.tariffPlans = response.data
          this.pagination.pageSize = parseInt(response.headers['x-pagination-per-page'])
          this.pagination.totalCount = parseInt(response.headers['x-pagination-total-count'])
          this.pagination.pageCount = parseInt(response.headers['x-pagination-page-count'])
        })
        .catch(() => {
          this.loading = false
        })
    },
    checkUrl: function () {
      this.fetchData()
    },
    linkGen(pageNum) {
      return {
        name: 'tariff-list',
        params: { page: pageNum }
      }
    },
    pageClickTPag(pageNum) {
      this.pagination.pageNumber = pageNum
      this.search.page = pageNum
      this.fetchData()
      return false
    },
    resetData() {
      this.search = {
        name: '',
        email: ''
      }
      this.fetchData()
    },
    deleteTariff: function (id) {
      if (confirm('Вы точно хотите удалить тариф?')) {
        let deleteUrl = 'dialoger/tariff-plan/delete?id=' + id
        this.true = false
        this.$http
          .delete(deleteUrl, {})
          .then(() => {
            this.loading = false
            this.fetchData()
            this.toast.success('Тариф удален')
          })
          .catch(() => {
            this.loading = false
            this.toast.error('Не удалось удалить тариф')
          })
      }
    }
  }
}
</script>
