<template>
  <div class="col">
    <div class="logon-form grey-bg br-40 mx-auto">
      <div class="d-flex top-nav text-center mb-5">
        <div class="w-50">
          <router-link :to="{ name: 'user-register' }" class="d-block">Регистрация</router-link>
        </div>
        <div class="w-50">
          <router-link :to="{ name: 'user-login' }" class="d-block active">Вход</router-link>
        </div>
      </div>
      <form @submit="submit" @keydown.enter="submit">
        <div class="mb-4">
          <input
            type="email"
            v-model="loginForm.login"
            placeholder="E-mail"
            class="form-control grey-bg"
          />
        </div>
        <div class="mb-3 input-group">
          <input
            :type="passwordField"
            v-model="loginForm.password"
            placeholder="Пароль"
            class="form-control grey-bg"
          />
          <span class="input-group-text" id="basic-addon2">
            <i
              class="bi"
              :class="passwordField === 'password' ? 'bi-eye-fill' : 'bi-eye-slash'"
              id="togglePassword"
              @click="togglePasswordField"
            ></i>
          </span>
        </div>
        <div class="forgot">
          <router-link :to="{ name: 'user-forgot' }"> Забыли пароль?</router-link>
        </div>
        <div class="d-grid">
          <button type="button" class="btn btn-primary btn-lg" @click="submit" id="register">
            Войти
          </button>
        </div>
        <template v-if="false">
          <div class="mb-3 continue">или продолжите с</div>
          <div class="d-flex justify-content-center">
            <a class="google-link" href="#"> </a>
            <a class="yandex-link" href="#"> </a>
          </div>
        </template>

        <div class="register_link">
          Нет аккаунта?
          <router-link :to="{ name: 'user-register' }"> Зарегистрироваться</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAuthUserStore } from '@/stores/user.js'
import { useAuthStore } from '@/stores/auth.js'
import router from '@/router/index.js'
import { useToast } from 'vue-toastification'
import { TYPE_ADMIN } from '@/modules/users/decorator/userDecorator.js'
export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'UserLogin',
  data() {
    return {
      loginForm: {
        login: '',
        password: ''
      },
      passwordField: 'password'
    }
  },
  mounted() {
    this.logout()
  },
  methods: {
    ...mapActions(useAuthStore, ['setToken']),
    ...mapActions(useAuthUserStore, ['setUserInfo']),
    ...mapActions(useAuthUserStore, ['logout']),
    togglePasswordField() {
      if (this.passwordField === 'password') {
        this.passwordField = 'text'
      } else {
        this.passwordField = 'password'
      }
      this.$forceUpdate
    },
    submit: function (e) {
      e.preventDefault()
      let valid = true
      if (valid) {
        let loginUrl
        loginUrl = '/users/security/login'
        this.$http
          .post(loginUrl, {
            'login-form': {
              login: this.loginForm.login,
              password: this.loginForm.password
            }
          })
          .then((response) => {
            let user = response.data.user
            this.setToken(user.authKey)
            this.setUserInfo({
              id: user.id,
              userType: user.type,
              name: user.name,
              authKey: user.authKey,
              access: user.access,
              profile: user.profile,
              isGuest: false
            })
            if (user.type === TYPE_ADMIN) {
              router.push({ name: 'user-list' })
            } else {
              router.push({ name: 'file-history' })
            }
          })
          .catch(() => {
            this.toast.error('Неверный логин или пароль')
          })
      } else {
        this.toast.error('Неверный логин или пароль')
        console.log('error submit!!')
        return false
      }
    }
  }
}
</script>
