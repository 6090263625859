<script setup>
import { ref } from 'vue'

const emit = defineEmits(['region-updated'])
const props = defineProps(['info'])

import { WaveSurferPlayer } from '@meersagor/wavesurfer-vue'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'

const options = ref({
  height: 38,
  waveColor: '#000',
  progressColor: '#3083FF',
  barGap: 1,
  barWidth: 1,
  url: props.info.file.url
})

const currentTime = ref('00:00')
const totalDuration = ref('00:00')
const waveSurfer = ref(null)
const wsRegions = ref(null)
const regStart = ref(0)
const regEnd = ref(props.info.duration)

const playing = ref(false)

const formatTime = (seconds) =>
  [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')

const regionChanged = (region, e) => {
  regStart.value = region.start
  regEnd.value = region.end
  console.log(['r', region.start, region.end, region, e])
  emit('region-updated', { start: region.start, end: region.end })
}
const timeUpdateHandler = (time) => {
  currentTime.value = formatTime(time)
}
const readyHandler = (duration) => {
  wsRegions.value = waveSurfer.value.registerPlugin(RegionsPlugin.create())
  totalDuration.value = formatTime(duration)
  wsRegions.value.on('region-updated', regionChanged)
  wsRegions.value.addRegion({
    start: regStart.value,
    end: duration,
    drag: true,
    resize: true,
    color: 'rgba(0, 0, 0, 0.0)'
  })
}

const playHandler = () => {
  playing.value = true
  console.log(playing)
}
const pauseHandler = () => {
  playing.value = false
  console.log(playing)
}
const readyWaveSurferHandler = (ws) => {
  waveSurfer.value = ws
}
</script>

<template>
  <div class="d-flex align-items-center">
    <button @click="waveSurfer?.playPause()" class="playbtn">
      <i v-if="!playing" class="bi bi-play-fill"></i>
      <i v-else class="bi bi-pause-fill"></i>
    </button>
    <div class="flex-grow-1 playerbody">
      <WaveSurferPlayer
        :options="options"
        @timeupdate="(time) => timeUpdateHandler(time)"
        @ready="(duration) => readyHandler(duration)"
        @waveSurfer="(ws) => readyWaveSurferHandler(ws)"
        @play="playHandler"
        @pause="pauseHandler"
      />
      <div class="d-flex justify-content-between durations">
        <div>{{ currentTime }}</div>
        <div>{{ totalDuration }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
