<template>
  <div v-if="loading" class="loading">
    <div class="spin"></div>
  </div>
  <div class="row">
    <div class="col-12 tarif text-center" v-if="!isAdmin">
      <h2>Мои <span class="text-primary"> минуты</span></h2>
      <div class="canceled">
        Доступно минут: <span class="text-dark">{{ stats.remainMinutes }}</span
        >, использовано минут:
        <span class="text-dark">{{ stats.usedMinutes }}</span>
      </div>
    </div>
    <div class="row justify-content-center" v-if="!isAdmin">
      <div v-for="tariff in tariffPlans" class="col-sm-12 col-md-4" v-bind:key="tariff.id">
        <div class="grey-bg plan br-40">
          <h4>{{ tariff.title }}</h4>
          <h4>{{ tariff.count }} мин</h4>
          <p class="text-center pre-wrap" v-html="tariff.body"></p>
          <div class="price">{{ tariff.price }} руб.</div>
          <div class="d-grid">
            <button class="btn btn-lg btn-primary" @click="orderPlan(tariff.id)">Купить</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <order-log ref="orderLog" />
    </div>
    <div class="col-12 mt-5 mb-5" v-if="!isAdmin">
      <div class="needhelp br-40">
        <div class="row align-items-center">
          <div class="col-md-7 col-sm-12 text-md-start mb-4 mb-md-0 text-center">
            <h2>Нужна помошь?</h2>
            Сообщите о вашей проблеме и мы постараемся ее решить
          </div>
          <div class="col-md-5 col-sm-12 text-md-end text-center">
            <router-link class="btn btn-outline-info btn-lg" :to="{ name: 'question' }"
              >Задать вопрос
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import { useRouter } from 'vue-router'
import router from '@/router/index.js'
import OrderLog from '@/modules/dialoger/views/OrderLog.vue'
import { useAuthUserStore } from '@/stores/user.js'
import { canAccessToUsers } from '@/modules/dialoger/access/access.js'
import {
  STATUS_SUCCESS,
  STATUS_NEW,
  STATUS_PROGRESSING,
  STATUS_CANCELED
} from '@/modules/dialoger/decorators/orderDecorator.js'

export default {
  components: { OrderLog },
  setup() {
    const toast = useToast()

    return { toast, router }
  },
  name: 'TariffPlan',
  data() {
    return {
      tariffPlans: [],
      stats: []
    }
  },
  mounted() {
    const route = useRouter()
    if (route.currentRoute.value.query.status === 'ok') {
      this.toast.success('Платеж обрабатывается банком')
    }
    if (route.currentRoute.value.query.status === 'error') {
      this.toast.error('Ошибка при оплате заказа')
    }
    if (route.currentRoute.value.query.orderId) {
      this.checkOrder(route.currentRoute.value.query.orderId)
    }
    route.push(route.currentRoute)
  },
  created() {
    this.fetchData()
    this.fetchStats()
  },
  computed: {
    loading() {
      return !this.stats.length && !this.tariffPlans.length
    },
    isAdmin() {
      const store = useAuthUserStore()
      return this.canAccessToUsers(store)
    }
  },
  methods: {
    checkOrder(orderId) {
      let checkUrl = '/dialoger/order/check-order'
      if (orderId) {
        this.$http
          .post(checkUrl, {
            orderId: orderId
          })
          .then((response) => {
            if (response.data.status === STATUS_SUCCESS) {
              this.fetchStats()
              this.$refs.orderLog.fetchData()
              this.toast.success('Платеж зачислен')
            }
            if (response.data.status === STATUS_CANCELED) {
              this.toast.error('Платеж не зачислен, попробуйте еще раз')
            }
            if (
              response.data.status === STATUS_PROGRESSING ||
              response.data.status === STATUS_NEW
            ) {
              this.toast.info('Платеж обрабатывается, обновите страницу немного позднее')
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    canAccessToUsers,
    fetchStats() {
      this.stats = []
      let statsUrl = '/dialoger/download/stats'
      this.$http
        .get(statsUrl)
        .then((response) => {
          this.stats = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    orderPlan(tariffId) {
      let orderUrl = '/dialoger/orders'
      this.$http
        .post(orderUrl, {
          tariffId: tariffId
        })
        .then((response) => {
          if (response.data.error) {
            this.toast.error(response.data.error)
          } else {
            window.open(response.data.link, '_blank')
          }
        })
        .catch(() => {
          this.toast.error('Ошибка отправки запроса')
        })
    },
    fetchData: function () {
      this.tariffPlans = []

      let usersUrl = '/dialoger/tariff-plan/'
      let params = {}
      this.$http
        .get(usersUrl, {
          params: params
        })
        .then((response) => {
          this.tariffPlans = response.data
        })
        .catch(() => {})
    }
  }
}
</script>
<style scoped>
.pre-wrap {
  white-space: pre-wrap;
  word-break: break-all;
}
</style>
