<template>
  <div v-if="loading" class="loading">
    <div class="spin"></div>
  </div>
  <div class="col">
    <div class="logon-form grey-bg br-40 mx-auto">
      <h2>Профиль: {{ header }}</h2>
      <user-form
        :user-model="user"
        :data-loaded="!loading"
        :return-url="{ name: 'user-list' }"
      ></user-form>
    </div>
  </div>
</template>

<script>
import { getUserTitle } from '@/modules/users/decorator/userDecorator.js'
import UserForm from '@/modules/users/views/UserForm.vue'
import { emptyUser } from '@/modules/users/decorator/userDecorator.js'
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'UserView',
  components: {
    'user-form': UserForm
  },
  data() {
    return {
      updating: false,
      infoTabIndex: 0,
      dataTabIndex: 0,
      user: emptyUser,
      loading: false
    }
  },
  computed: {
    isNew() {
      return this.id === 'new'
    },
    id() {
      return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    header() {
      if (this.isNew) {
        return 'Новый пользователь'
      }
      return getUserTitle(this.user)
    }
  },
  watch: {
    $route() {
      this.checkUrl()
    }
  },
  created: function () {
    this.checkUrl()
  },

  methods: {
    getUserTitle,
    // fetching data
    checkUrl: function () {
      if (this.id !== 'new') {
        this.fetchData()
      }
    },
    fetchData: function () {
      this.error = null
      this.user.phone = ''
      let usersUrl = '/users/admin/' + this.id
      this.loading = true
      this.$http
        .get(usersUrl, {})
        .then((response) => {
          this.loading = false
          this.user = response.data
        })
        .catch(() => {
          this.loading = false
          this.toast.error('Не удалось загрузить данные пользователя')
        })
    },
    deleteUser() {
      if (confirm('Вы действительно хотите удалить пользователя?')) {
        let deleteUrl = '/users/users/' + this.user.id
        this.$http
          .delete(deleteUrl)
          .then(() => {
            this.loading = false
            this.$router.push({ name: 'user-list' })
            this.toast.success('Пользователь удален')
          })
          .catch(() => {
            this.loading = false

            this.toast.error('Не удалось удалить пользователя')
          })
      }
    }
  }
}
</script>
