<template>
  <div class="col">
    <div class="logon-form grey-bg br-40 mx-auto">
      <div class="d-flex text-center mb-0">
        <h2>Аккаунт</h2>
      </div>
      <form @submit="submit" @keydown.enter="submit" id="accForm">
        <div class="mb-4">
          <input type="text" v-model="name" placeholder="Имя" class="form-control grey-bg" />
        </div>
        <div class="mb-3">
          <input
            type="email"
            disabled
            v-model="email"
            placeholder="E-mail"
            class="form-control grey-bg"
          />
        </div>
        <div class="edit-link">
          <router-link :to="{ name: 'user-password' }">Сменить пароль</router-link>
        </div>

        <div class="d-grid">
          <button type="button" class="btn btn-primary btn-lg" id="register" @click="submit">
            Сохранить
          </button>
          <router-link :to="{ name: 'user-login' }" class="btn btn-danger" id="logout">
            Выйти</router-link
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { useAuthUserStore } from '@/stores/user.js'
import { useToast } from 'vue-toastification'
export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  mounted() {
    this.setupFromStorage()
  },
  name: 'UserAccount',
  data() {
    return {
      name: '',
      email: ''
    }
  },
  methods: {
    submit: function (e) {
      let form = document.getElementById('accForm')
      let valid = form.checkValidity()
      if (valid) {
        let loginUrl
        loginUrl = '/users/profile/update'
        this.$http
          .post(loginUrl, {
            email: this.email,
            name: this.name
          })
          .then((response) => {
            const storage = useAuthUserStore()
            storage.profile.email = response.data.email
            storage.profile.name = response.data.name
            this.toast.success('Профиль обновлен')
          })
          .catch((error) => {
            this.toast.error(error.response.data.errors.email[0])
          })
      } else {
        this.toast.error('Введите корренктный Email адрес')
      }
      e.preventDefault()
    },
    setupFromStorage() {
      const storage = useAuthUserStore()
      this.name = storage.profile.name
      this.email = storage.profile.email
    }
  }
}
</script>
