<template>
  <div v-if="loading" class="loading">
    <div class="spin"></div>
  </div>
  <div class="col" id="report">
    <div class="row" v-if="inProgress">
      <div class="spin"></div>
      <div class="wait text-center">
        Ваш файл обрабатывается
        <div class="time">Осталось 5 минут</div>
      </div>
    </div>
    <div class="row" v-if="isReady">
      <div class="general col-12">
        <h2>Общая информация</h2>
        <div class="grey-bg br-40">
          <div class="row align-items-center">
            <div class="col-sm-12 col-md-8">
              <div class="row">
                <div class="col-12">
                  <h5>General Information</h5>
                </div>
                <div class="col-sm-12 col-md-6">
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td>Customer</td>
                        <td>{{ getUserTitle(download.user) }}</td>
                      </tr>

                      <tr>
                        <td>Date and Time</td>
                        <td>{{ download.result.date_result }}</td>
                      </tr>
                      <tr>
                        <td>Duration</td>
                        <td>{{ download.result.duration }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-sm-12 col-md-6">
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td>File Name</td>
                        <td>{{ download.file.name + '.' + download.file.extension }}</td>
                      </tr>
                      <tr>
                        <td>Review Status</td>
                        <td>To Review</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-4">
              <div class="d-grid">
                <button class="btn btn-lg btn-primary" @click="generateReport">Скачать PDF</button>
                <button class="btn btn-lg btn-dark" @click="openJson">Скачать json</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="diar col-12">
        <div class="grey-bg br-40">
          <div class="row">
            <div class="col-10">
              <h2>Диаризация</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="details" style="font-size: 20rem">
                <file-audio
                  :info="download"
                  @region-updated="(start, end) => setRegion(start, end)"
                >
                </file-audio>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="diar col-12">
        <div class="grey-bg br-40">
          <div class="row">
            <div class="col-10">
              <h2>Транскрибация</h2>
            </div>
            <div class="col-2">
              <div class="quest">
                ?
                <div class="details">
                  <h4>Диаризация</h4>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse
                </div>
              </div>
            </div>
            <div class="col-12 chat">
              <div class="white-bg">
                <div class="persons color-list text-end" v-if="speakers.length">
                  <ul class="list-group list-group-horizontal justify-content-end">
                    <template v-for="(speaker, index) in speakers" v-bind:key="index">
                      <li class="list-group-item">
                        <span class="color" :class="colors[speaker]"></span>Спикер {{ speaker + 1 }}
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="messages" v-if="result">
                  <div
                    v-for="(message, index) in result"
                    v-bind:key="index"
                    class="d-flex align-items-end"
                    :class="getSpeakerClass(message.speaker)"
                  >
                    <div class="person person1-ico">
                      <span
                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                        :class="'bg-' + colors[message.speaker]"
                      >
                        {{ parseInt(message.speaker) + 1 }}
                      </span>
                    </div>
                    <div class="person-message">
                      <div class="message">
                        {{ message.text }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="segment col-12">
        <div class="grey-bg br-40">
          <div class="row">
            <div class="col-10">
              <h2>Сегментация</h2>
            </div>
            <div class="col-2">
              <div class="quest">?</div>
            </div>
            <div class="col-12">
              <div class="white-bg">
                <div class="progress">
                  <div
                    v-for="(speaker, index) in speakers"
                    v-bind:key="index"
                    class="progress-bar"
                    :class="'bg-' + colors[speaker]"
                    role="progressbar"
                    :style="'width:' + getSpeakerVoicePercent(speaker) + '%'"
                    :aria-valuenow="getSpeakerVoicePercent(speaker)"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ getSpeakerVoicePercent(speaker) }}%<span
                      :class="'round bg-' + colors[speaker]"
                    ></span>
                  </div>
                </div>
                <div class="color-list text-end">
                  <ul class="list-group list-group-horizontal justify-content-start">
                    <template v-for="(speaker, index) in speakers" v-bind:key="index">
                      <li class="list-group-item">
                        <span class="color" :class="colors[speaker]"></span>Спикер {{ speaker + 1 }}
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="download col align-self-center text-center">
        <a class="btn btn-primary text-center dbutton">
          Скачать текст диалога
          <i class="bi bi-arrow-down"></i>
        </a>
      </div>
      <div class="segment col-12">
        <div class="grey-bg br-40">
          <div class="row">
            <div class="col-10">
              <h2>Тегирование</h2>
            </div>
            <div class="col-2">
              <div class="quest">?</div>
            </div>
            <div class="col-12">
              <div class="white-bg">
                <div class="progress">
                  <div
                    v-for="(segment, index) in resultSegments"
                    v-bind:key="index"
                    class="progress-bar"
                    :class="'bg-' + getReverseColorClass(segment.id)"
                    role="progressbar"
                    :style="'width:' + getSegmentPercent(segment.id) + '%'"
                    :aria-valuenow="getSegmentPercent(segment.id)"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span class="number"> {{ getSegmentPercent(segment.id) }}%</span
                    ><span class="round" :class="'bg-' + getReverseColorClass(segment.id)"></span>
                  </div>
                </div>
                <div class="color-list text-end">
                  <ul class="list-group list-group-horizontal justify-content-start">
                    <li
                      v-for="(segment, index) in resultSegments"
                      class="list-group-item"
                      v-bind:key="index"
                    >
                      <span class="color" :class="'bg-' + getReverseColorClass(segment.id)"></span
                      >{{ segment.title }}
                      <span class="number d-inline d-md-none">
                        - {{ getSegmentPercent(segment.id) }}%</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="report col-12">
        <div class="grey-bg br-40">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <h3>{{ download.result.result.summaries[0] }}</h3>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="d-grid d-md-flex buttons">
                <a role="button" class="btn btn-lg btn-primary mb-2" @click="generateReport">
                  Скачать PDF
                </a>
                <a href="" class="btn btn-lg btn-dark mb-2" @click="openJson"> Скачать JSON </a>
              </div>
            </div>
            <div class="col-12">
              <div class="color-list text-end">
                <ul class="list-group list-group-horizontal justify-content-start">
                  <li v-for="(ner, index) in ners" class="list-group-item" v-bind:key="index">
                    <span class="color" :class="'bg-' + colors[index]"></span> {{ ner }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 recognized-text">
              <span
                v-for="(result, index) of result"
                :key="index"
                v-html="getResultHtml(result)"
              ></span>
              <i class="bi bi-chevron-down more"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="download col-12 text-center">
        <a class="btn btn-primary dbutton">
          Скачать отчет
          <i class="bi bi-arrow-down"></i>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {STATUS_FINISHED, STATUS_NEW, STATUS_PROCESSED, STATUS_PROGRESS} from '@/modules/dialoger/decorators/download.js'
import {useToast} from 'vue-toastification'
import {getUserTitle} from '@/modules/users/decorator/userDecorator.js'
import FileAudio from '@/modules/file/views/FileAudio.vue'
import html2pdf from 'html2pdf.js'

export default {
  components: { FileAudio },
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'FileReport',
  data() {
    return {
      region: { start: 0, end: 0 },
      download: {},
      hasResult: false,
      timeOut: null,
      colors: [
        'primary',
        'danger',
        'success',
        'warning',
        'secondary',
        'custom-1',
        'custom-2',
        'custom-3',
        'custom-4',
        'custom-5',
        'custom-6'
      ]
    }
  },
  computed: {
    loading() {
      return !this.download.id
    },
    result() {
      let result = []
      if (this.download?.result?.result?.result?.length) {
        if (this.region.end || this.region.start) {
          result = this.download.result.result.result.filter((item) => {
            if (item.start_time <= this.region.start && item.end_time >= this.region.end) {
              return true
            }
            if (item.start_time >= this.region.start && item.start_time <= this.region.end) {
              return true
            }
            return item.end_time >= this.region.start && item.end_time <= this.region.end
          })
        } else {
          result = this.download.result.result.result
        }
      }

      return result
    },
    id() {
      return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    inProgress() {
      return [STATUS_PROGRESS, STATUS_PROCESSED].includes(this.download.status)
    },
    isReady() {
      return this.download.status === STATUS_FINISHED
    },
    speakers() {
      let speakers = []
      if (this.result.length) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (let result of this.result) {
          if (!speakers.includes(parseInt(result.speaker))) {
            speakers.push(parseInt(result.speaker))
          }
        }
      }
      return speakers
    },
    segments() {
      let segments = []
      if (this.download?.result?.dicts?.segments) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (let segment of Object.entries(this.download?.result?.dicts?.segments)) {
          segments.push({ id: segment[0], title: segment[1] })
        }
      }
      return segments
    },
    resultSegments() {
      let segments = []
      segments = this.segments.filter((item) => {
        let hasSegment = false
        for (let result of this.result) {
          if (result.segments === item.id) {
            hasSegment = true
          }
        }
        return hasSegment
      })
      return segments
    },
    totalVoiceDuration() {
      let duration = 0
      if (this.result) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (let result of this.result) {
          duration += result.end_time - result.start_time
        }
      }
      return duration
    },
    ners() {
      let ners = []
      if (this.result) {
        for (let result of this.result) {
          if (result.ners.length) {
            for (let ner of result.ners) {
              if (!ners.includes(ner[3])) {
                ners.push(ner[3])
              }
            }
          }
        }
      }
      return ners
    }
  },
  watch: {
    $route() {
      this.fetchData()
    },
    download: {
      handler(newVal) {
        if (newVal.status === STATUS_NEW) {
          this.toast.error('У вас недостаночно минут для распознавания данного файла')
          this.$router.push({ name: 'file-history' })
        }
        if (newVal.status === STATUS_PROGRESS) {
          this.timeOut = setTimeout(this.fetchData, 5000)
        }
        if (newVal.status === STATUS_FINISHED && this.timeOut) {
          if (this.timeOut) {
            clearTimeout(this.timeOut)
          }
        }
      },
      deep: true
    }
  },
  created: function () {
    this.fetchData()
  },
  unmounted() {
    if (this.timeOut) {
      clearTimeout(this.timeOut)
    }
  },
  methods: {
    generateReport() {
      let element = document.getElementById('report')
      let opt = {
        margin: 5,
        filename: 'report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 200, scale: 1, letterRendering: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', compress: true },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      }

      html2pdf().set(opt).from(element).save()
    },
    openJson() {
      let tab = window.open('about:blank', '_blank')
      tab.document.write(JSON.stringify(this.download.result)) // where 'html' is a variable containing your HTML
      tab.document.close()
    },
    setRegion(region) {
      this.region.start = region.start
      this.region.end = region.end
    },
    getUserTitle,
    getResultHtml(result) {
      let content = result.text
      if (!result.ners.length) {
        return content + ' '
      } else {
        for (let ner of result.ners) {
          let find = ner[0]
          let index = this.getNerIndex(ner[3])
          let html =
            '<span class="tag bg-' +
            this.colors[index] +
            '">' +
            find +
            '<span class="tagname">' +
            ner[3] +
            '</span></span> '
          content = content.replace(find, html)
        }
        return content
      }
    },
    getNerIndex(ner) {
      return this.ners.indexOf(ner)
    },
    getReverseColorClass(segment) {
      let colors = [...this.colors].reverse()
      for (let [index, value] of this.segments.entries()) {
        if (value.id === segment) {
          return colors[index]
        }
      }
    },
    getSegmentDuration(segment) {
      let duration = 0
      if (this.result) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (let result of this.result) {
          if (result.segments === segment) {
            duration += result.end_time - result.start_time
          }
        }
      }
      return duration
    },
    getSpeakerVoiceDuration(speaker) {
      let duration = 0
      if (this.result) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (let result of this.result) {
          if (result.speaker === speaker) {
            duration += result.end_time - result.start_time
          }
        }
      }
      return duration
    },
    getSpeakerVoicePercent(speaker) {
      if (typeof speaker === 'number') {
        speaker = speaker.toString()
      }
      return ((this.getSpeakerVoiceDuration(speaker) / this.totalVoiceDuration) * 100).toFixed(2)
    },
    getSegmentPercent(segment) {
      return ((this.getSegmentDuration(segment) / this.totalVoiceDuration) * 100).toFixed(2)
    },
    getSpeakerClass(speaker) {
      let number = parseInt(speaker)
      let column = number % 2
      if (column) {
        return 'flex-row-reverse'
      } else {
        return 'justify-content-start'
      }
    },
    fetchData: function () {
      this.error = null
      let downloadUrl = '/dialoger/downloads/' + this.id

      this.$http
        .get(downloadUrl, {})
        .then((response) => {
          this.download = response.data
        })
        .catch(() => {
          this.toast.error('Не удалось загрузить данные')
        })
    }
  }
}
</script>
