<template>
  <div class="col">
    <div class="logon-form grey-bg br-40 mx-auto">
      <div class="d-flex top-nav text-center mb-5">
        <div class="w-50">
          <router-link :to="{ name: 'user-register' }" class="d-block">Регистрация</router-link>
        </div>
        <div class="w-50">
          <router-link :to="{ name: 'user-login' }" class="d-block active">Вход</router-link>
        </div>
      </div>
      <form @submit="submit" @keydown.enter="submit">
        <div class="mb-4">
          <input
            type="email"
            v-model="loginForm.login"
            placeholder="E-mail"
            class="form-control grey-bg"
          />
        </div>
        <div class="mb-4" v-if="sent">
          <input
            type="text"
            v-model="loginForm.code"
            placeholder="Код"
            class="form-control grey-bg"
          />
        </div>
        <div class="mb-4" v-if="loginForm.code">
          <input
            type="password"
            placeholder="Новый пароль"
            v-model="loginForm.password"
            class="form-control grey-bg"
          />
        </div>

        <div class="d-grid">
          <button type="button" class="btn btn-primary btn-lg" @click="submit" id="register">
            Восстановить пароль
          </button>
        </div>
        <template v-if="false">
          <div class="mb-3 continue">или продолжите с</div>
          <div class="d-flex justify-content-center">
            <a class="google-link" href="#"> </a>
            <a class="yandex-link" href="#"> </a>
          </div>
        </template>

        <div class="register_link">
          Нет аккаунта?
          <router-link :to="{ name: 'user-register' }"> Зарегистрироваться</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import router from '@/router/index.js'
import { useToast } from 'vue-toastification'
export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'UserForgot',
  data() {
    return {
      loginForm: {
        login: '',
        code: '',
        password: ''
      },
      sent: false
    }
  },
  mounted() {},
  methods: {
    submit: function (e) {
      e.preventDefault()
      if (!this.sent) {
        let loginUrl
        loginUrl = '/users/recovery/request'
        this.$http
          .post(loginUrl, {
            login: this.loginForm.login
          })
          .then(() => {
            this.toast.success('Инструкция отправлена на почту')
            this.sent = true
          })
          .catch((error) => {
            this.toast.error(error.response.data.errors.login)
          })
      } else {
        if (!this.loginForm.code) {
          this.toast.error('Введите код')
          return false
        }
        if (this.loginForm.password.length < 6) {
          this.toast.error('Введите пароль не менее 6 символов ')
          return false
        }
        let loginUrl =
          '/users/recovery/reset?login=' + this.loginForm.login + '&code=' + this.loginForm.code
        this.$http
          .post(loginUrl, {
            password: this.loginForm.password
          })
          .then(() => {
            this.toast.success('Пароль изменен')
            router.push({ name: 'user-login' })
          })
          .catch((error) => {
            this.toast.error(error.response.data.errors.code)
          })
      }
    }
  }
}
</script>
