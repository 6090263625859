export const STATUS_NEW = 1
export const STATUS_SUCCESS = 2
export const STATUS_CANCELED = 3
export const STATUS_PROGRESSING = 4

export const TYPE_ADMIN_CHARGE = 2
export const TYPE_PAYMENT = 1

export const STATUS_TITLES = [
  { id: 1, title: 'Новый' },
  { id: 2, title: 'Покупка завершена' },
  { id: 3, title: 'Покупка отклонена' }
]
export const getOrderStatusTitle = function (order) {
  for (let status of STATUS_TITLES) {
    if (order.status === status.id) {
      return status.title
    }
  }
  return 'Неизвестный статус'
}

export const getTariffName = function (order) {
  if (order.type === TYPE_PAYMENT) {
    return order.tariffPlan.title
  } else {
    return 'Без тарифа'
  }
}

export const getOrderType = function (order) {
  if (order.type === TYPE_PAYMENT) {
    return 'Покупка пользователя'
  }
  if (order.type === TYPE_ADMIN_CHARGE) {
    return 'Начислено администратором'
  }
  return 'Не определено'
}
