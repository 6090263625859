<template>
  <div v-if="loading" class="loading">
    <div class="spin"></div>
  </div>
  <div class="row">
    <div v-if="false" class="col-12 tarif text-center">
      <h2>Ваш тарифный план: <span class="text-primary">Business</span></h2>
      <div class="canceled">Тариф оплачен до: <span class="text-dark">19.02.2024</span></div>
    </div>
    <div class="col-12" v-if="fileLoading">
      <div class="spin"></div>
      <div class="wait text-center">Файл загружается</div>
    </div>
    <template v-else>
      <div class="col-12 text-center mt-5">
        <h2>Распознавание</h2>
      </div>
      <div class="col-12">
        <div class="row align-items-top">
          <div class="col-sm-12 col-md-6 stats text-center text-md-start mb-4 mb-md-0" v-if="stats">
            <div id="progresses">
              <h6>Минуты</h6>
              <div class="d-sm-block d-md-flex">
                <div class="progress flex-grow-1" style="height: 20rem">
                  <div
                    v-if="minutePercent"
                    class="progress-bar"
                    role="progressbar"
                    :style="'width:' + minutePercent + '%'"
                  ></div>
                </div>
                <div>
                  доступно <b>{{ stats.remainMinutes }}</b> мин из
                  <b>{{ stats.orderedMinutes }}</b> мин
                </div>
              </div>

              <h6>Хранилище</h6>
              <div class="d-sm-block d-md-flex">
                <div class="progress flex-grow-1" style="height: 20rem">
                  <div
                    v-if="spacePercent"
                    class="progress-bar"
                    role="progressbar"
                    :style="'width:' + spacePercent + '%'"
                  ></div>
                </div>
                <div>
                  доступно <b>{{ stats.remainSpace }}</b> MB из <b>{{ stats.maxUserSpace }}</b> MB
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 d-grid d-md-block text-end">
            <button class="btn btn-primary dbutton" @click="chooseFile">
              Перейти к загрузке
              <i class="bi bi-arrow-down"></i>
            </button>
            <div class="file-formats text-end mt-2 me-3">
              Обрабатываю все форматы со звуком, до {{ stats.maxFileSize }} Mб
            </div>
            <input type="file" ref="file" id="file" @input="startUpload" />
          </div>
        </div>
      </div>
      <div class="col-12 download-table">
        <div class="table-responsive grey-bg br-40">
          <form @submit.prevent="fetchData" @keyup.enter="fetchData">
            <div class="search d-flex mb-4 align-items-end">
              <div class="form-group me-2">
                <label>Имя файла</label>
                <input type="text" v-model="search.title" class="form-control mr-2" />
              </div>

              <div>
                <a class="btn btn-info btn-lg ms-1" @click="resetSearch">
                  <i class="bi bi-eraser"></i>
                  Очистить
                </a>
              </div>
            </div>
          </form>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" @click="changeSort">
                  Дата загрузки
                  <i v-if="!sortAsc" class="bi bi-caret-down-fill"></i>
                  <i v-else class="bi bi-caret-up-fill"></i>
                </th>
                <th scope="col">Имя файла</th>
                <th scope="col">Тип файла</th>
                <th scope="col">Размер файла</th>
                <th scope="col">Длительность</th>
                <th scope="col">Скачать</th>
                <th scope="col">Слушать</th>
                <th></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(download, index) in downloads" v-bind:key="index">
                <td class="align-middle">{{ getDownloadTime(download) }}</td>
                <td class="align-middle text-start">
                  <router-link
                    v-if="showLink"
                    :to="{ name: 'file-report', params: { id: download.id } }"
                  >
                  </router-link>
                  <template v-else>
                    {{ getDownloadFileName(download) }}
                  </template>
                </td>
                <td class="align-middle">{{ getDownloadFileExtension(download) }}</td>
                <td class="align-middle">{{ getDownloadSize(download) }}</td>
                <td class="align-middle">{{ getTrackDuration(download) }}</td>
                <td class="icons align-middle">
                  <i
                    class="download-audio"
                    role="button"
                    @click="downloadAudio(download)"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Скачать оригинальный файл"
                  ></i>
                  <i
                    class="bi bi-filetype-docx"
                    v-if="download.resultFile?.id"
                    role="button"
                    @click="downloadDocx(download)"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Скачать расшифровку"
                  ></i>
                </td>
                <td class="align-middle">
                  <a href="#" class="play" @click="playDownload(download, $event)">
                    <i class="bi bi-play-fill" v-if="!isPlaying(download)"></i>
                    <i v-else class="bi bi-pause-fill"></i>
                  </a>
                </td>
                <td class="status align-middle">
                  <i
                    class="bi"
                    :class="getStatusIcon(download)"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="getDownloadStatusTitle(download)"
                  ></i>
                </td>
                <td class="align-middle trash">
                  <i
                    class="bi bi-trash3 text-danger"
                    role="button"
                    @click="deleteDownload(download.id)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <t-pagination
            v-if="pagination.pageCount > 1"
            :link-gen="linkGen"
            :pagination="pagination"
            @page-selected="pageClickTPag"
          />
        </div>
        <div class="needhelp br-40 mb-5">
          <div class="row align-items-center">
            <div class="col-md-7 col-sm-12 text-md-start mb-4 mb-md-0 text-center">
              <h2>Нужна помошь?</h2>
              Сообщите о вашей проблеме и мы постараемся ее решить
            </div>
            <div class="col-md-5 col-sm-12 text-md-end text-center">
              <router-link class="btn btn-outline-info btn-lg" :to="{ name: 'question' }"
                >Задать вопрос
              </router-link>
            </div>
          </div>
        </div>
        <div class="d-none">
          <audio-player
            ref="player"
            :option="{
              src: currentFile
            }"
            @ended="audioEnded"
            @loadedmetadata="startPlay"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AudioPlayer from 'vue3-audio-player'
import 'vue3-audio-player/dist/style.css'
import { paginationOptions } from '@/modules/dialoger/decorators/common.js'
import TPagination from '@/components/layout/TPagination.vue'
import {
  getDownloadFileExtension,
  getDownloadFileName,
  getDownloadSize,
  getDownloadTime,
  getStatsMegaBytes,
  getStatsMinutes,
  getTrackDuration,
  STATUS_ERROR,
  STATUS_FINISHED,
  STATUS_NEW,
  STATUS_PROCESSED,
  STATUS_PROGRESS
} from '@/modules/dialoger/decorators/download.js'
import { useToast } from 'vue-toastification'
import { Tooltip } from 'bootstrap'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'FileHistory',
  components: { AudioPlayer, TPagination },
  data() {
    return {
      fileLoading: false,
      downloads: [],
      pagination: {
        totalCount: 0,
        pageNumber: 1,
        pageCount: 0,
        pageSize: 10,
        options: paginationOptions
      },
      search: {
        title: ''
      },
      downloadsLoading: false,
      sortAsc: false,
      stats: [],
      currentFile: '',
      currentDownloadPlaying: 0,
      playing: false,
      showLink: false,
      timerId: 0
    }
  },
  mounted: function () {
    this.fetchData()
    this.fetchStats()
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']"
    })
    this.timerId = setInterval(() => {
      this.fetchData()
      this.downloadsLoading = false
      this.fetchStats(false)
    }, 30000)
  },
  unmounted() {
    clearInterval(this.timerId)
  },
  computed: {
    minutePercent() {
      return 100 - (this.stats.usedMinutes / this.stats.orderedMinutes) * 100
    },
    spacePercent() {
      return 100 - (this.stats.usedSpace / this.stats.maxUserSpace) * 100
    },
    loading() {
      return this.downloadsLoading && !this.stats.length
    },
    fileFormats() {
      if (!this.stats.formats?.length) {
        return ''
      }
      return this.stats.formats.join(', ')
    }
  },
  methods: {
    getStatusIcon(download) {
      if ([STATUS_PROGRESS, STATUS_PROCESSED].includes(download.status)) {
        return 'bi-clock'
      }
      if (download.status === STATUS_NEW) {
        return 'bi-clock text-danger'
      }
      if (download.status === STATUS_FINISHED) {
        return 'bi-check-circle'
      }
      if (download.status === STATUS_ERROR) {
        return 'bi-exclamation-circle text-danger'
      }
    },
    getDownloadStatusTitle(download) {
      if ([STATUS_PROGRESS, STATUS_PROCESSED].includes(download.status)) {
        return 'Расшифровка в работе'
      }
      if (download.status === STATUS_NEW) {
        return 'Недостаточно минут'
      }
      if (download.status === STATUS_FINISHED) {
        return 'Расшифровка готова'
      }
      if (download.status === STATUS_ERROR) {
        return 'Невозможно распознать файл'
      }
    },
    changeSort() {
      this.sortAsc = !this.sortAsc
      this.fetchData()
    },
    resetSearch() {
      this.search.title = ''
      this.fetchData()
    },
    startPlay() {
      this.$refs.player.play()
    },
    downloadAudio(download) {
      let tab = window.open('about:blank')
      tab.location = download.file.url
    },
    downloadDocx(download) {
      let tab = window.open('about:blank')
      tab.location = download.resultFile.url
    },
    startUpload(e) {
      let formData = new FormData()
      this.file = e.target.files[0]

      let fileSize = this.file.size / 1024 / 1024
      if (fileSize > this.stats.maxFileSize) {
        this.toast.error('Вы не можете загрузить файлы больше ' + this.stats.maxFileSize + ' Мб')
        return false
      }
      if (fileSize > this.stats.remainSpace) {
        this.toast.error('Недостаточно места в хранилище для загрузки файла')
        return false
      }
      formData.append('file', this.file)
      this.fileLoading = true
      this.$http
        .post('/dialoger/download/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.data.status === 'ok') {
            this.fileLoading = false
            this.toast.success('Файл успешно загружен, ожидайте уведомления о готовности')
          } else {
            this.fileLoading = false
            for (let error in response.data.errors) {
              this.toast.error(response.data.errors[error])
            }
          }
          this.fetchData()
          this.fetchStats()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    chooseFile(e) {
      e.preventDefault()
      if (!this.stats.maxFileSize) return
      this.$refs.file.click()
    },
    getStatsMinutes,
    getStatsMegaBytes,
    getTrackDuration,
    getDownloadSize,
    getDownloadTime,
    getDownloadFileName,
    getDownloadFileExtension,
    linkGen(pageNum) {
      return {
        name: 'file-history',
        params: { page: pageNum }
      }
    },
    audioEnded() {
      this.playing = false
    },
    pageClickTPag(pageNum) {
      this.pagination.pageNumber = pageNum
      this.fetchData()
      return false
    },
    fetchData: function () {
      this.tariffPlans = []
      this.downloadsLoading = true
      let usersUrl = '/dialoger/download/'
      let sort = this.sortAsc ? 'id' : '-id'
      let params = {
        limit: this.pagination.pageSize,
        page: this.pagination.pageNumber,
        search: this.search.title,
        sort: sort
      }
      this.$http
        .get(usersUrl, {
          params: params
        })
        .then((response) => {
          this.downloadsLoading = false
          this.downloads = response.data
          this.pagination.pageSize = parseInt(response.headers['x-pagination-per-page'])
          this.pagination.totalCount = parseInt(response.headers['x-pagination-total-count'])
          this.pagination.pageCount = parseInt(response.headers['x-pagination-page-count'])
        })
        .catch(() => {
          this.downloadsLoading = false
        })
    },
    fetchStats(clear = true) {
      if (clear) {
        this.stats = []
      }
      let statsUrl = '/dialoger/download/stats'
      this.$http
        .get(statsUrl)
        .then((response) => {
          this.stats = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    isPlaying(download) {
      return this.playing && download.id === this.currentDownloadPlaying
    },
    deleteDownload(id) {
      let deleteUrl = '/dialoger/downloads/' + id
      if (confirm('Вы действительно хотите удалить файл?')) {
        this.$http
          .delete(deleteUrl)
          .then(() => {
            this.toast.success('Файл удален')
            this.fetchData()
            this.fetchStats()
          })
          .catch(() => {
            this.toast.error('Ошибка удаления файла')
          })
      }
    },
    playDownload(download, e) {
      e.preventDefault()
      if (download.id === this.currentDownloadPlaying) {
        this.playing = !this.playing
      } else {
        this.playing = true
        this.currentDownloadPlaying = download.id
        this.currentFile = download.file.url
      }
      if (this.playing) {
        this.$refs.player.$forceUpdate()
        this.$refs.player.play()
      } else {
        this.$refs.player.pause()
      }
    }
  }
}
</script>
<style>
.progress-bar {
  border-radius: 10rem;
}
</style>
