import DialogerQuest from '@/modules/dialoger/views/DialogerQuest.vue'
import { canAccessToUsers, checkGuest } from '@/modules/dialoger/access/access.js'

import TariffPlanView from '@/modules/dialoger/views/tariffplan/TariffPlanView.vue'
import TariffPlanList from '@/modules/dialoger/views/tariffplan/TariffPlanList.vue'
import TariffPlan from '@/modules/dialoger/views/tariffplan/TariffPlan.vue'

import SettingsPage from '@/modules/dialoger/views/SettingsPage.vue'
export default [
  {
    path: '/question',
    name: 'question',
    components: { default: DialogerQuest },
    meta: {
      requiresAuth: true,
      checkRule: checkGuest
    }
  },
  {
    path: '/tariff-create',
    name: 'tariff-create',
    components: {
      default: TariffPlanView
    },
    meta: {
      requiresAuth: true,
      checkRule: canAccessToUsers
    }
  },
  {
    path: '/tariff/:id/update',
    name: 'tariff-update',
    components: {
      default: TariffPlanView
    },
    meta: {
      requiresAuth: true,
      checkRule: canAccessToUsers
    }
  },
  {
    path: '/tariff/list/:page?',
    name: 'tariff-list',
    components: {
      default: TariffPlanList
    },
    meta: {
      requiresAuth: true,
      checkRule: canAccessToUsers
    }
  },
  {
    path: '/plan',
    name: 'tariff-plan',
    components: { default: TariffPlan },
    meta: {
      requiresAuth: true,
      checkRule: checkGuest
    }
  },
  {
    path: '/settings',
    name: 'settings',
    components: { default: SettingsPage },
    meta: {
      requiresAuth: true,
      checkRule: canAccessToUsers
    }
  }
]
