<template>
  <form v-if="dataLoaded" id="user-form" ref="user" class="user-form admin-form">
    <div class="mb-4">
      <div class="label">Тип пользователя</div>
      <select v-model="user.type" class="form-control" required>
        <option
          v-for="userType in userTypesTitles"
          v-bind:key="userType.value"
          v-bind:value="userType.value"
        >
          {{ userType.text }}
        </option>
      </select>
    </div>

    <div class="mb-4">
      <div class="label">Имя</div>
      <input v-model="user.name" placeholder="Имя" class="form-control" maxlength="255" />
    </div>

    <div class="mb-4">
      <div class="label">E-mail</div>
      <input
        v-model="user.email"
        placeholder="mail@example.com"
        required
        type="email"
        disabled
        class="form-control"
        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
        maxlength="255"
      />
    </div>

    <div class="col-12 mb-4">
      <div class="label">Пароль</div>
      <input
        v-model="user.newPassword"
        type="password"
        placeholder=""
        autocomplete="new"
        class="form-control"
        maxlength="255"
      />
    </div>

    <div class="mb-4" v-if="id !== 'new'">
      <div class="label">Количество доступных минут</div>
      <input
        v-model.number="user.minuteCount"
        type="text"
        placeholder=""
        disabled="disabled"
        autocomplete="new"
        class="form-control"
        v-mask="'#########'"
        @input="fixNumber"
      />
    </div>

    <div class="d-sm-grid d-md-flex mb-5">
      <button
        class="btn btn-success btn-lg mb-2 me-md-3 d-flex justify-content-center align-items-center"
        @click="createOrUpdate"
      >
        {{ submitButton }}
      </button>

      <button
        v-if="id !== 'new'"
        class="btn btn-warning btn-lg mb-2 me-md-3 d-flex justify-content-center align-items-center"
        @click="cancelUpdate"
      >
        К списку
      </button>

      <button
        v-if="user.id > 0"
        class="btn btn-danger btn-lg mb-2 me-md-3 d-flex justify-content-center align-items-center"
        @click="remove"
      >
        Удалить
      </button>
    </div>
    <div class="row" v-if="id !== 'new'">
      <div class="col-12">
        <h2>Начислить минуты</h2>
      </div>
      <div class="col-12">
        <div class="input-group mb-3">
          <input
            type="text"
            min="0"
            step="1"
            v-model.number="minutes"
            class="form-control"
            @focus="minutes = ''"
            v-mask="'##########'"
            @input="fixNumber"
          />
          <button @click="submitMinutes" class="btn btn-primary btn-lg">Начислить</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { userTypesTitlesAsSelectOptions } from '@/modules/users/decorator/userDecorator'
import clone from 'just-clone'
import { useToast } from 'vue-toastification'
export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'UserForm',
  props: ['userModel', 'dataLoaded'],
  data() {
    return {
      userTypesTitles: userTypesTitlesAsSelectOptions,
      user: {
        newPassword: ''
      },
      genders: [
        { text: 'M', value: 'male' },
        { text: 'Ж', value: 'female' }
      ],
      loading: false,
      submitButton: 'Сохранить',
      minutes: 0
    }
  },
  computed: {
    id() {
      return this.user.id
    },
    name() {
      let name = this.user.name ? this.user.name : 'Новый пользователь'
      return name ? name : 'Пользователь'
    }
  },
  watch: {
    $route() {},
    userModel: {
      immediate: true,
      handler(obj) {
        this.user = clone(obj)
        this.user.minuteCount = Math.round(this.user.secondCount / 60)
      }
    }
  },
  created: function () {},
  mounted: function () {},
  methods: {
    fixNumber(e) {
      if (parseInt(e.target.value)) {
        e.target.value = parseInt(e.target.value)
      } else {
        e.target.value = ''
      }
    },
    setUserData(data) {
      this.user = data
      this.user.enabled = !!data.enabled
      this.user.minuteCount = Math.round(this.user.secondCount / 60)
    },
    update: function () {
      let updateUrl
      updateUrl = 'users/admin/' + this.id
      this.loading = true
      this.$http
        .put(updateUrl, this.processUserVariable(this.user))
        .then((response) => {
          this.setUserData(response.data)
          this.loading = false
          this.toast.success('Пользователь отредактирован')
        })
        .catch(() => {
          this.loading = false
          this.toast.error('Не удалось отредактировать пользователя')
        })
    },
    create: function () {
      let updateUrl
      updateUrl = 'users/admin'
      this.loading = true
      this.$http
        .post(updateUrl, this.processUserVariable(this.user))
        .then((response) => {
          this.user = response.data
          this.toast.success('Пользователь добавлен')
          this.$router.push({ name: 'user-list' })
        })
        .catch(() => {
          this.loading = false
          this.toast.error('Не удалось добавить пользователя')
        })
    },

    processUserVariable(user) {
      let params = {
        email: user.email,
        type: user.type,
        name: user.name,
        password: user.newPassword,
        secondCount: user.minuteCount * 60
      }
      if (user.id && user.id !== 'new') {
        params.id = user.id
      }
      return params
    },

    createOrUpdate: function (event) {
      event.preventDefault()
      let form = document.getElementById('user-form')
      let valid = form.checkValidity()
      form.classList.add('was-validated')
      if (valid) {
        if (this.id === 'new') {
          this.create()
        } else {
          this.update()
        }
        form.classList.remove('was-validated')
      } else {
        this.toast.error('В форме содержатся ошибки')
        return false
      }
    },
    remove: function (e) {
      if (confirm('Вы действительно хотите удалить пользователя?')) {
        e.preventDefault()
        let deleteUrl = 'users/admin/delete?id=' + this.id
        this.true = false
        this.$http
          .delete(deleteUrl, {})
          .then(() => {
            this.loading = false
            this.$router.push({ name: 'user-list' })
            this.toast.success('Пользователь удален')
          })
          .catch(() => {
            this.loading = false
            this.toast.error('Не удалось удалить пользователя')
          })
      }
    },

    cancelUpdate() {
      this.$router.push({ name: 'user-list' })
    },
    submitMinutes(e) {
      e.preventDefault()
      let url = 'dialoger/order/admin-charge'
      if (!parseInt(this.minutes)) {
        console.log(this.minutes)
        this.toast.error('Введите количество минут')
        return false
      }
      this.$http
        .post(url, {
          minutes: this.minutes,
          userId: this.user.id
        })
        .then((response) => {
          if (response.data.status === 'ok') {
            this.toast.success('Минуты начислены успешно')
            this.user.minuteCount += this.minutes
            this.minutes = 0
          }
        })
        .catch((error) => {
          console.log(error)
          this.toast.error('Что то пошло не так')
        })
    }
  }
}
</script>
