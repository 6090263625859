<template>
  <div class="col">
    <div class="logon-form grey-bg br-40 mx-auto">
      <div class="d-flex text-center mb-0">
        <h2>Смена пароля</h2>
      </div>
      <form @submit="submit" @keydown.enter="submit">
        <div class="mb-4">
          <input
            type="password"
            v-model="oldPassword"
            placeholder="Текущий пароль"
            class="form-control grey-bg"
          />
        </div>
        <div class="mb-4">
          <input
            type="password"
            v-model="newPassword"
            placeholder="Новый пароль"
            class="form-control grey-bg"
          />
        </div>
        <div class="mb-4">
          <input
            type="password"
            v-model="repeatPassword"
            placeholder="Подтрердите пароль"
            class="form-control grey-bg"
          />
        </div>

        <div class="d-grid">
          <button type="button" class="btn btn-primary btn-lg" id="register" @click="submit">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { useToast } from 'vue-toastification'
export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'UserPassword',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      repeatPassword: ''
    }
  },
  methods: {
    submit: function (e) {
      e.preventDefault()
      let valid = this.newPassword === this.repeatPassword
      if (valid) {
        let loginUrl = '/users/profile/change-password'
        this.$http
          .post(loginUrl, {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword
          })
          .then(() => {
            this.toast.success('Новый пароль сохранен')
            this.$router.push({ name: 'user-edit' })
          })
          .catch((error) => {
            if (error.response.data.errors) {
              // eslint-disable-next-line no-unused-vars
              for (const [errorField, message] of Object.entries(error.response.data.errors)) {
                this.toast.error(message[0])
              }
            }
          })
      } else {
        this.toast.error('Не совпадают новые пароли')
        return false
      }
    }
  }
}
</script>
