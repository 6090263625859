<template>
  <div class="col">
    <div class="logon-form grey-bg br-40 mx-auto">
      <div class="d-flex top-nav text-center mb-5">
        <div class="w-50">
          <router-link :to="{ name: 'user-register' }" class="d-block active"
            >Регистрация</router-link
          >
        </div>
        <div class="w-50">
          <router-link :to="{ name: 'user-login' }" class="d-block">Вход</router-link>
        </div>
      </div>
      <form @submit="submit" @keydown.enter="submit" id="regForm">
        <div class="mb-4">
          <input
            type="email"
            placeholder="E-mail"
            v-model="loginForm.login"
            class="form-control grey-bg"
          />
        </div>
        <div class="mb-4 input-group">
          <input
            :type="passwordField"
            v-model="loginForm.password"
            placeholder="Пароль"
            class="form-control grey-bg"
          />
          <span class="input-group-text" id="basic-addon2">
            <i
              class="bi"
              :class="passwordField === 'password' ? 'bi-eye-fill' : 'bi-eye-slash'"
              id="togglePassword"
              @click="togglePasswordField"
            ></i>
          </span>
        </div>
        <div class="input-group">
          <input
            :type="passwordField1"
            placeholder="Повторите пароль"
            v-model="loginForm.repeatPassword"
            class="form-control grey-bg"
          />
          <span class="input-group-text" id="basic-addon2">
            <i
              class="bi"
              :class="passwordField1 === 'password' ? 'bi-eye-fill' : 'bi-eye-slash'"
              id="togglePassword"
              @click="togglePasswordField1"
            ></i>
          </span>
        </div>
        <div class="d-grid">
          <button type="submit" class="btn btn-primary btn-lg" id="register" @click="submit">
            Зарегистрироватся
          </button>
        </div>
        <template v-if="false">
          <div class="mb-3 continue">или продолжите с</div>
          <div class="d-flex justify-content-center">
            <a class="google-link" href="#"> </a>
            <a class="yandex-link" href="#"> </a>
          </div>
        </template>
      </form>
    </div>
  </div>
</template>
<script>
import router from '@/router/index.js'
import { useToast } from 'vue-toastification'
import { mapActions } from 'pinia'
import { useAuthStore } from '@/stores/auth.js'
import { useAuthUserStore } from '@/stores/user.js'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'UserRegister',
  data() {
    return {
      loginForm: {
        login: '',
        password: '',
        repeatPassword: ''
      },
      passwordField: 'password',
      passwordField1: 'password'
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['setToken']),
    ...mapActions(useAuthUserStore, ['setUserInfo']),
    submit: function (e) {
      let form  = document.getElementById('regForm');
      let validForm =  form.checkValidity();
      let validPassword =  this.loginForm.password === this.loginForm.repeatPassword
      let valid = validForm && validPassword
      if (valid) {
        let loginUrl
        loginUrl = '/users/registration/register'
        this.$http
          .post(loginUrl, {
            email: this.loginForm.login,
            password: this.loginForm.password
          })
          .then((response) => {
            let user = response.data.user
            this.setToken(user.authKey)
            this.setUserInfo({
              id: user.id,
              userType: user.type,
              name: user.name,
              authKey: user.authKey,
              access: user.access,
              profile: user.profile,
              isGuest: false
            })
            router.push('/')
          })
          .catch((error) => {
            console.log(error)
            this.toast.error(error.response.data.errorMessage)
          })
      } else {
        if(!validForm){
          this.toast.error('Введите корректный email')
        }
        if(!validPassword){
          this.toast.error('Пароли не совпадают')
        }

        return false
      }
      e.preventDefault()
    },
    togglePasswordField() {
      if (this.passwordField === 'password') {
        this.passwordField = 'text'
      } else {
        this.passwordField = 'password'
      }
      this.$forceUpdate
    },
    togglePasswordField1() {
      if (this.passwordField1 === 'password') {
        this.passwordField1 = 'text'
      } else {
        this.passwordField1 = 'password'
      }
      this.$forceUpdate
    }
  }
}
</script>
