<template>
  <div v-if="loading" class="loading">
    <div class="spin"></div>
  </div>
  <div class="col">
    <div class="logon-form grey-bg br-40 mx-auto">
      <h2>Тариф : {{ header }}</h2>
      <tariff-form :tariff-model="tariff" :data-loaded="!loading"></tariff-form>
    </div>
  </div>
</template>

<script>
import TariffPlanForm from '@/modules/dialoger/views/tariffplan/TariffPlanForm.vue'
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'TariffPlanView',
  components: {
    'tariff-form': TariffPlanForm
  },
  data() {
    return {
      updating: false,
      infoTabIndex: 0,
      dataTabIndex: 0,
      tariff: {
        id: 'new',
        title: '',
        body: '',
        price: 0,
        count: 0
      },
      loading: false
    }
  },
  computed: {
    isNew() {
      return this.id === 'new'
    },
    id() {
      return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    header() {
      if (this.isNew) {
        return 'Новый тариф'
      }
      return this.tariff.title
    }
  },
  watch: {
    $route() {
      this.checkUrl()
    }
  },
  created: function () {
    this.checkUrl()
  },

  methods: {
    // fetching data
    checkUrl: function () {
      if (this.id !== 'new') {
        this.fetchData()
      }
    },
    fetchData: function () {
      this.error = null
      let usersUrl = '/dialoger/tariff-plans/' + this.id
      this.loading = true
      this.$http
        .get(usersUrl, {})
        .then((response) => {
          this.loading = false
          this.tariff = response.data
        })
        .catch(() => {
          this.loading = false
          this.toast.error('Не удалось загрузить данные ')
        })
    }
  }
}
</script>
