import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import mitt from 'mitt'
import router from './router'
import VueApi from '@/plugins/api'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toast, { POSITION } from 'vue-toastification'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import './assets/variables.scss'
import './assets/app.css'
import 'bootstrap'
import 'vue-toastification/dist/index.css'
import VMask from '@ssibrahimbas/v-mask'
const eventHub = mitt()
export const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(router)
app.use(Toast, { position: POSITION.TOP_RIGHT, timeout: 3000 })
app.use(VueApi)
app.use(VMask)
app.config.globalProperties.eventHub = eventHub
app.mount('#app')
