<script>
import Header from '@/components/layout/Header.vue'

export default {
  components: { 'page-header': Header }
}
</script>

<template>
  <page-header></page-header>
  <div id="content">
    <div class="container-fluid">
      <router-view></router-view>
    </div>
  </div>
</template>
