import axios from 'axios'
import { useAuthStore } from '@/stores/auth.js'
import { app } from '@/main.js'

let base
if (import.meta.env.MODE === 'production') {
  let origin = window.location.origin
  base = origin + '/backend/api'
  // base = import.meta.env.VITE_API_PROD_URL;
  // socket = import.meta.env.VITE_SOCKET_PROD_URL;
} else {
  base = import.meta.env.VITE_API_DEV_URL
}

export function apiSetToken(token) {
  app.config.globalProperties.$http.defaults.headers.common.Authorization = token
}

export function getAxiosInstance() {
  const AuthStore = useAuthStore()
  const token = AuthStore.getToken

  // api
  const instance = axios.create({
    baseURL: base
  })

  if (token) {
    instance.defaults.headers.common.Authorization = token
  }

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error)
    }
  )

  return instance
}

export const BASE_URL = base
