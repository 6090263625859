<template>
  <div v-if="loading" class="loading">
    <div class="spin"></div>
  </div>
  <div class="col">
    <div class="logon-form grey-bg br-40 mx-auto">
      <h2>Настройки</h2>
      <form id="settings-form" class="user-form admin-form">
        <div class="mb-4">
          <div class="label">E-mail адрес для уведомлений</div>
          <input
            v-model="settings.adminEmail"
            required
            type="email"
            class="form-control"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
            maxlength="255"
          />
        </div>

        <div class="mb-4">
          <div class="label">Количество стартовых минут</div>
          <input
            v-model.number="settings.userStartMinutes"
            type="text"
            placeholder=""
            required
            @focus="settings.userStartMinutes = ''"
            v-mask="'####'"
            class="form-control"
            @input="fixNumber"
            @change="fixNumber"
          />
        </div>

        <div class="mb-4">
          <div class="label">Обьем хранилища по умолчанию, MB</div>
          <input
            v-model.number="settings.userStartSpace"
            placeholder=""
            type="text"
            v-mask="'######'"
            required
            @input="fixNumber"
            @change="fixNumber"
            @focus="settings.userStartSpace = ''"
            class="form-control"
          />
        </div>
        <div class="mb-4">
          <div class="label">Максимальный размер загружаемого файла, MB</div>
          <input
              v-model.number="settings.maxFileSize"
              placeholder=""
              type="text"
              v-mask="'######'"
              required
              @input="fixNumber"
              @change="fixNumber"
              @focus="settings.maxFileSize = ''"
              class="form-control"
          />
          <small>Не более 100 Мб</small>
        </div>
        <div class="mb-4">
          <div class="label">Пороговое значение минут до уведомления</div>
          <input
            v-model="settings.alertMinutes"
            placeholder=""
            type="text"
            @focus="settings.alertMinutes = ''"
            @input="fixNumber"
            v-mask="'######'"
            @change="fixNumber"
            required
            class="form-control"
          />
        </div>
        <div class="mb-4">
          <div class="label">Пороговое значение MB до уведомления</div>
          <input
            v-model.number="settings.alertSpace"
            placeholder=""
            type="text"
            required
            @focus="settings.alertSpace = ''"
            @input="fixNumber"
            v-mask="'######'"
            @change="fixNumber"
            class="form-control"
          />
        </div>
        <div class="d-sm-grid d-md-flex mb-5">
          <button
            class="btn btn-success btn-lg mb-2 me-md-3 d-flex justify-content-center align-items-center"
            @click="saveSettings"
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const toast = useToast()
    return { toast }
  },
  name: 'SettingsPage',
  components: {},
  data() {
    return {
      updating: false,
      settings: {},
      loading: false
    }
  },
  computed: {},
  watch: {},
  created: function () {
    this.fetchData()
  },

  methods: {
    fixNumber(e) {
      if (parseInt(e.target.value)) {
        e.target.value = parseInt(e.target.value)
      } else {
        e.target.value = 0
      }
    },
    saveSettings(e) {
      e.preventDefault()
      let form = document.getElementById('settings-form')
      let valid = form.checkValidity()
      form.classList.add('was-validated')
      if (valid) {
        let settingsUrl = 'dialoger/default/set-settings'
        this.loading = true
        this.$http
          .post(settingsUrl, {
            adminEmail: this.settings.adminEmail,
            userStartSpace: this.settings.userStartSpace,
            userStartMinutes: this.settings.userStartMinutes,
            alertMinutes: this.settings.alertMinutes,
            alertSpace: this.settings.alertSpace,
            maxFileSize : this.settings.maxFileSize,
          })
          .then((response) => {
            this.loading = false
            this.settings = response.data
            this.toast.success('Настройки сохранены')
          })
          .catch((error) => {
            this.loading = false
            this.toast.error('Не удалось сохранить настройки ')
            for (const [key, value] of Object.entries(error.response.data)) {
             value.forEach((errorMsg)=>this.toast.error(errorMsg))
            }
          })
        form.classList.remove('was-validated')
      } else {
        this.toast.error('В форме содержатся ошибки')
        return false
      }
    },
    fetchData: function () {
      this.error = null
      let settingsUrl = '/dialoger/default/get-settings'
      this.loading = true
      this.$http
        .get(settingsUrl, {})
        .then((response) => {
          this.loading = false
          this.settings = response.data
        })
        .catch(() => {
          this.loading = false
          this.toast.error('Не удалось загрузить данные ')
        })
    }
  }
}
</script>
