<template>
  <div class="pagination">
    <nav aria-label="">
      <ul class="pagination justify-content-center">
        <li class="page-item"><a class="page-link" href="#" @click="toPage(1, $event)">«</a></li>
        <li class="page-item" v-if="showPrev">
          <a class="page-link" href="#" @click="toPage(pagination.pageNumber - 1, $event)"><</a>
        </li>
        <li
          v-for="(i, index) in list"
          :key="index"
          class="page-item"
          :class="{ active: isActive(i) }"
        >
          <a class="page-link" @click="toPage(i, $event)">{{ i }}</a>
        </li>
        <li v-if="showNext" class="page-item">
          <a class="page-link" @click="toPage(pagination.pageNumber + 1, $event)">›</a>
        </li>
        <li class="page-item">
          <a class="page-link" @click="toPage(pagination.pageCount, $event)">»</a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="btn-group btn-group-toggle"></div>
</template>

<script>
export default {
  name: 'TPagination',
  emits: ['page-selected'],
  components: {},
  props: {
    pagination: { type: Object }
  },
  data() {
    return {}
  },
  computed: {
    showPrev() {
      return this.pagination.pageNumber > 1
    },
    showNext() {
      return this.pagination.pageNumber <= this.pagination.pageCount - 1
    },
    list() {
      let pageCount = this.pagination.pageCount
      let list = []
      let all = []
      if (pageCount) {
        for (let i = 1; i <= pageCount; i++) {
          all.push(i)
        }
        for (let i of all) {
          if (this.showLink(i)) {
            list.push(i)
          }
        }
      }

      return list
    }
  },
  watch: {},
  mounted() {},

  methods: {
    showLink(number) {
      return (
        number > 0 &&
        number <= this.pagination.pageCount &&
        Math.abs(number - this.pagination.pageNumber) <= 5
      )
    },
    isActive(number) {
      return number === this.pagination.pageNumber
    },
    toPage(pageNum, event) {
      if (event) {
        event.preventDefault()
      }
      this.$emit('page-selected', pageNum)
    }
  }
}
</script>
